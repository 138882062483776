import makeSharedPayload from './shared';

export default (pageName, asErrorPage, rest, asUnauthenticatedPage, config) => ({
  ...makeSharedPayload(pageName, asUnauthenticatedPage, config, rest),
  ...(asErrorPage
    ? {
        event_action: 'system',
        event_category: 'error',
        event_content: pageName.toLowerCase(),
      }
    : {}),
  ...rest,
});
