import { useDevSettings } from '@icoz-frontends/dev-settings';
import { fflags } from '@icoz-frontends/shared';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useEffect } from 'react';
import useIsFeatureEnabled from '../../hooks/useIsFeatureEnabled';

const EnsureAuthentication = ({
  LoadingPage,
  applicationId,
  authRoute,
  children,
  expressHost,
  inboundCountry,
  isAuthenticated,
}) => {
  const isFeatureEnabled = useIsFeatureEnabled();
  const devSettings = useDevSettings({ parse: false });

  useEffect(() => {
    if (!isAuthenticated) {
      window.location.href = qs.stringifyUrl({
        query: {
          applicationId,
          feature_USE_AUTH: isFeatureEnabled(fflags.USE_AUTH),
          feature_USE_DSP_AUTH: isFeatureEnabled(fflags.USE_DSP_AUTH),
          feature_USE_STUBS: isFeatureEnabled(fflags.USE_STUBS),
          host: window.location.host,
          inboundCountry,
          ...(devSettings ? { DEV_SETTINGS: devSettings } : {}),
        },
        url: expressHost ? `${expressHost}${authRoute}` : authRoute,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return isAuthenticated ? children : <LoadingPage show />;
};

EnsureAuthentication.defaultProps = {
  expressHost: undefined,
};

EnsureAuthentication.propTypes = {
  LoadingPage: PropTypes.func.isRequired,
  applicationId: PropTypes.string.isRequired,
  authRoute: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  expressHost: PropTypes.string,
  inboundCountry: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default EnsureAuthentication;
