import useTealiumTracking, { trackView } from '../../hooks/tracking/useTealiumTracking';
import useConfig from '../../hooks/useConfig';

export default () => {
  const { tealiumTracking } = useConfig();
  const pageConfig = tealiumTracking?.events?.sessionTimeoutPopup;

  if (pageConfig) {
    const { restData, title } = pageConfig;

    useTealiumTracking(title, [trackView(restData, true)]);
  }
};
