import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { formatDate } from '../../dataFormatters';
import DOC_UPLOAD from './docUploadConfig';

const userDataHasRequiredFields = data =>
  data.applicationId &&
  data.name &&
  data.email &&
  data.dateOfBirth &&
  data.mobileTelephoneNumber?.value &&
  data.currentResidentialAddress?.value;

const getUserMetadata = userData => {
  if (!userDataHasRequiredFields(userData)) {
    /**
     * We can only inlude user metadata if the required properties exist.
     * DocUpload rejects any requests which contain metadata objects with
     * empty `value` fields. In reality this check should always pass,
     * since the required user data is prefilled from CDM.
     */
    return [];
  }

  return [
    {
      name: 'FS_Customer_Name',
      type: 'String',
      value: userData.name,
    },
    {
      name: 'FS_Sender_Address',
      type: 'String',
      value: userData.email,
    },
    {
      name: 'ICO_Customer_Date_Of_Birth',
      type: 'DateTime',
      value: formatDate(userData.dateOfBirth, 'YYYY-MM-DDTHH:mm:ss.SSS'),
    },
    {
      name: 'ICO_Customer_Phone',
      type: 'String',
      value: userData.mobileTelephoneNumber.value,
    },
    {
      name: 'ICO_Customer_Address',
      type: 'String',
      value: userData.currentResidentialAddress.value.substring(0, 99),
    },
  ];
};

/**
 * A `documentProperties` array is included in the request data that's sent
 * to the DocUpload service. This array contains metadata relating to the file,
 * the application it was uploaded from, and the user who uploaded it.
 * File and application metadata is used by DocUpload to correctly route
 * files to FileNet. User metadata can be used by IBC staff to search
 * for files.
 *
 * @param {File} file
 * @param {Object} userData - wizardPage `values`
 * @returns {Object[]}
 */
const getDocumentProperties = (_file, userData, corridorConfig = {}) => {
  const DOC_UPLOAD_CONFIG = {
    ...DOC_UPLOAD,
    ...corridorConfig,
  };

  return [
    {
      name: 'FS_Entity_Code',
      type: 'String',
      value: DOC_UPLOAD_CONFIG.groupMbrCde,
    },
    {
      name: 'FS_Country_Code',
      type: 'String',
      value: DOC_UPLOAD_CONFIG.ctryCde,
    },
    {
      name: 'FS_Line_Of_Business',
      type: 'String',
      value: DOC_UPLOAD_CONFIG.lineOfBus,
    },
    {
      name: 'FS_Ingestion_Channel',
      type: 'String',
      value: DOC_UPLOAD_CONFIG.ingestionChannel,
    },
    {
      name: 'FS_Information_Classification',
      type: 'String',
      value: DOC_UPLOAD_CONFIG.informationClassification,
    },
    {
      // Unique for each file
      name: 'FS_Document_Tracking_ID',
      type: 'String',
      value: nanoid(),
    },
    {
      // Date of upload (format: `YYYY-MM-DDTHH:MM:SS`)
      name: 'FS_Document_Receive_Date',
      type: 'DateTime',
      value: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    },
    {
      name: 'FS_Application_ID',
      type: 'String',
      value: userData.applicationId,
    },
    {
      name: 'FS_Document_Type',
      type: 'String',
      value: 'Other',
    },
    ...getUserMetadata(userData),
  ];
};

/**
 * An object containing `documentData` is included in each
 * request to the DocUpload service.
 *
 * @param {File} file
 * @param {Object} userData - wizardPage `values`
 * @param {string} applicationId
 * @returns {Object}
 */
export default (file, userData, corridorConfig = {}) => ({
  documentData: {
    ...DOC_UPLOAD,
    ...corridorConfig,
    documentProperties: getDocumentProperties(file, userData, corridorConfig),
  },
});
