import Cookie from 'js-cookie';
import { useMemo } from 'react';
import generateApplicationId from '../utils/generateApplicationId';

declare global {
  interface Window {
    __applicationId: string;
  }
}

export default (inboundCountry: string): string => {
  const cookieApplicationId = Cookie.get('applicationId');

  const applicationId = useMemo(() => {
    if (cookieApplicationId) {
      Cookie.remove('applicationId');
      return cookieApplicationId;
    }

    return generateApplicationId();
  }, [inboundCountry]); // eslint-disable-line react-hooks/exhaustive-deps

  if (typeof window !== 'undefined') {
    window.__applicationId = applicationId;
  }

  return applicationId;
};
