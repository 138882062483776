import { useEffect, useState } from 'react';
import useCorridorApps from './useCorridorApps';

export default (inboundCountry, { importKey } = {}) => {
  const { corridorImports, moduleNames = [] } = useCorridorApps(importKey);
  const [corridorModules, setCorridorModules] = useState({});

  useEffect(() => {
    if (corridorImports[inboundCountry]) {
      corridorImports[inboundCountry]().then(result => {
        setCorridorModules(
          Object.keys(result).reduce((loaded, key) => {
            if (moduleNames.includes(key)) {
              loaded[key] = result[key];
            }

            return loaded;
          }, {}),
        );
      });
    }
  }, [inboundCountry, importKey]); // eslint-disable-line react-hooks/exhaustive-deps

  return corridorModules;
};
