import { Processing as CommonProcessing, useProcessingTracking } from '@icoz-frontends/client';
import Translate from '@icoz-frontends/translate/macro';
import * as PropTypes from 'prop-types';
import React from 'react';

const Processing = props => {
  const { isOnSubmit, texts } = props;
  const tealiumTracking = isOnSubmit ? useProcessingTracking : undefined;
  return <CommonProcessing texts={texts} {...props} tealiumTracking={tealiumTracking} />;
};

Processing.defaultProps = {
  isOnSubmit: undefined,
  tealiumTracking: undefined,
  texts: {
    content: <Translate>processing.paragraph</Translate>,
    heading: <Translate>processing.heading</Translate>,
    title: Translate('processing.heading', { locale: 'en' }),
  },
};

Processing.propTypes = {
  isOnSubmit: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  tealiumTracking: PropTypes.func,
  texts: PropTypes.shape({
    content: PropTypes.node,
    heading: PropTypes.node,
    title: PropTypes.string,
  }),
};

export default Processing;
