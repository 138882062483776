import PropTypes from 'prop-types';
import React from 'react';
import Translations from './Translations';

const TransformJsonStringToJsx = ({ jsonString }) => {
  try {
    const { options, translations } = JSON.parse(jsonString);
    const { inline, isIconLabel, isLabel, styles } = options;

    return (
      <Translations
        inline={inline}
        isIconLabel={isIconLabel}
        isLabel={isLabel}
        styles={styles}
        translations={translations}
      />
    );
  } catch {
    return jsonString;
  }
};

TransformJsonStringToJsx.propTypes = {
  jsonString: PropTypes.string.isRequired,
};

export default TransformJsonStringToJsx;
