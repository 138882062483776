import PropTypes from 'prop-types';
import React from 'react';
import AriaModal from 'react-aria-modal';
import { Transition } from 'react-transition-group';
import styles from './FullScreenModal.module.css';

const propTypes = {
  children: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
};

const FullScreenModal = ({ children, id, show }) => {
  const titleId = `${id}-title`;
  const wrapperId = `${id}-wrapper`;

  return (
    <Transition in={show} mountOnEnter timeout={{ enter: 20, exit: 200 }} unmountOnExit>
      <AriaModal
        dialogClass={styles.dialog}
        focusTrapOptions={{ escapeDeactivates: false }}
        getApplicationNode={() => document.getElementById('root')}
        includeDefaultStyles={false}
        initialFocus={`#${wrapperId}`}
        titleId={titleId}
        underlayClass={styles.underlay}
        underlayClickExits={false}
      >
        <div className={styles.wrapper} id={wrapperId} tabIndex={-1}>
          {children(titleId)}
        </div>
      </AriaModal>
    </Transition>
  );
};

FullScreenModal.propTypes = propTypes;

export default FullScreenModal;
