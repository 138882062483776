import Cookie from 'js-cookie';

export default (_values, initialValue, value) => {
  if (initialValue === value) {
    return;
  }

  if (value === 'PROD') {
    Cookie.remove('authenticated');
    Cookie.remove('gatewaytimeout');
    Cookie.remove('featureFlagOverrides');
  }

  if (value === 'DEV') {
    Cookie.remove('authenticated');
    Cookie.remove('gatewaytimeout');
  }
};
