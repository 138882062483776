import Translate from '@icoz-frontends/translate/macro';
import { Heading, ListItem, OrderedList, Paragraph, Section } from '@piggybank/core';
import React from 'react';

const WelcomeFallbackContent = () => (
  <>
    <Section>
      <Heading accentBar level={2}>
        <Translate>welcomePage.fallback.howApply.heading</Translate>
      </Heading>
      <Heading level={3}>
        <Translate>welcomePage.fallback.howApply.subHeading1</Translate>
      </Heading>
      <OrderedList>
        <ListItem>
          <Heading level={4}>
            <Translate>welcomePage.fallback.howApply.subHeading2</Translate>
          </Heading>
          <Paragraph marginBottom={2}>
            <Translate>welcomePage.fallback.howApply.listItem1</Translate>
          </Paragraph>
        </ListItem>
        <ListItem>
          <Heading level={4}>
            <Translate>welcomePage.fallback.howApply.subHeading3</Translate>
          </Heading>
          <Paragraph marginBottom={2}>
            <Translate>welcomePage.fallback.howApply.listItem2</Translate>
          </Paragraph>
          <Paragraph hint marginBottom={2}>
            <Translate>welcomePage.fallback.howApply.hint</Translate>
          </Paragraph>
        </ListItem>
        <ListItem>
          <Heading level={4}>
            <Translate>welcomePage.fallback.howApply.subHeading4</Translate>
          </Heading>
          <Paragraph marginBottom={2}>
            <Translate>welcomePage.fallback.howApply.listItem3</Translate>
          </Paragraph>
        </ListItem>
      </OrderedList>
    </Section>

    <Section>
      <Heading accentBar level={2}>
        <Translate>welcomePage.fallback.howToBook.heading</Translate>
      </Heading>
      <Paragraph>
        <Translate>welcomePage.fallback.howToBook.paragraph</Translate>
      </Paragraph>
      <Heading level={4} marginBottom={3}>
        <Translate>welcomePage.fallback.howToBook.subHeading</Translate>
      </Heading>
      <Paragraph>+852 2233 3888</Paragraph>
    </Section>
  </>
);

export default WelcomeFallbackContent;
