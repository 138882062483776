import { getFirstPartOfPageName, slugify } from '../utils';

const securityLevels = {
  authenticated: '40',
  unauthenticated: '0',
};

export default (pageName, asUnauthenticatedPage, config) => ({
  customer_group: config.customerGroup,
  funnel_name: `${config.funnelName}${config.funnelCorridor}`,
  funnel_step_name: pageName.toLowerCase(),
  internal_reference_id: config.applicationId,
  page_business_line: config.businessLine,
  page_category: config.funnelName,
  page_customer_group: config.pageCustomerGroup,
  page_language: config.language,
  page_name: `${config.siteSection}:${config.funnelName}:${getFirstPartOfPageName(pageName)}`,
  page_security_level: asUnauthenticatedPage ? securityLevels.unauthenticated : securityLevels.authenticated,
  page_subcategory: getFirstPartOfPageName(pageName),
  page_type: config.defaultPageType,
  page_url: `/${slugify(config.funnelName)}/${slugify(pageName)}`,
  product_category: config.productCategory,
  product_id: config.productId,
  product_subcategory: config.productSubCategory,
  site_country: config.siteCountry,
  site_region: config.siteRegion,
  site_section: config.siteSection,
});
