import { Checkbox, Field } from '@piggybank/form';
import PropTypes from 'prop-types';
import React from 'react';

const Feature = ({ description, name }) => {
  const featureName = name.indexOf(':') === -1 ? name : name.split(':')[1];

  if (featureName === 'SG') {
    return '';
  }

  return (
    <Field marginBottom={0} name={name}>
      <Checkbox>
        <strong>{featureName}</strong>: {description}
      </Checkbox>
    </Field>
  );
};

Feature.propTypes = {
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Feature;
