import { Field as DefaultField, Label, TelephoneInput } from '@piggybank/form';
import PropTypes from 'prop-types';
import React from 'react';
import { formatTelephoneNumber } from '../../../dataFormatters';
import FieldWithAnnounce from '../../FieldWithAnnounce';
import { LockedDataKey, LockedDataValue } from '../../LockedDataList/LockedDataList';
import { FieldFeedback } from '../../piggybank';

const TelephoneNumber = ({
  announce,
  defaultCountryCode,
  label,
  lockedFallbackValue,
  lockedHeading,
  lockedValue,
  name,
  required,
  ...rest
}) => {
  if (lockedHeading) {
    return (
      <>
        <LockedDataKey>{lockedHeading}</LockedDataKey>
        <LockedDataValue>{formatTelephoneNumber(lockedValue) || lockedFallbackValue}</LockedDataValue>
      </>
    );
  }

  const Field = announce ? FieldWithAnnounce : DefaultField;

  return (
    <Field announce={announce} name={name} required={required} {...rest}>
      <Label>{label}</Label>
      <TelephoneInput countryCode={defaultCountryCode} />
      <FieldFeedback />
    </Field>
  );
};

TelephoneNumber.defaultProps = {
  announce: undefined,
  defaultCountryCode: undefined,
  label: undefined,
  lockedFallbackValue: 'Not provided',
  lockedHeading: undefined,
  lockedValue: undefined,
  name: undefined,
  required: true,
};

TelephoneNumber.propTypes = {
  announce: PropTypes.string,
  defaultCountryCode: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  lockedFallbackValue: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  lockedHeading: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  lockedValue: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
};

export default TelephoneNumber;
