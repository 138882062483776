import {
  Button,
  FormLayout,
  Heading,
  HorizontalRule,
  IconStatusInformationOnlight,
  ListItem,
  OrderedList,
  Paragraph,
  Section,
  getMarginBottomClass,
} from '@piggybank/core';
import PropTypes from 'prop-types';
import React from 'react';
import useConfig from '../../hooks/useConfig';
import useLoggerEffect from '../../hooks/useLoggerEffect';
import PageTitle from '../PageTitle/PageTitle';
import ScrollToTopWrapper from '../ScrollToTopWrapper/ScrollToTopWrapper';
import styles from './PreExistingCustomerData.module.css';

const PreExistingCustomerDataErrorSegment = ({ CustomerCareContent, onCTAKeyDown, textMap }) => {
  const { dashboardUrl, logEventCodes } = useConfig();
  useLoggerEffect(logEventCodes.SEGMENT_ERROR);

  return (
    <ScrollToTopWrapper>
      <PageTitle>{textMap.title}</PageTitle>

      <FormLayout>
        <Section>
          <div className={`${styles.icon} ${getMarginBottomClass(3)}`}>
            <IconStatusInformationOnlight scaled />
          </div>
          <Heading level={1}>{textMap.heading}</Heading>
          <Paragraph lead>{textMap.leadParagraph}</Paragraph>
          <HorizontalRule />
        </Section>

        <Section>
          <Heading accentBar level={2}>
            {textMap.applyHeading}
          </Heading>
          <Heading level={3}>{textMap.applySubHeading}</Heading>
          <OrderedList>
            <ListItem>
              <Heading level={4}>{textMap.applyBullet1}</Heading>
              <Paragraph marginBottom={2}>{textMap.applyBullet1Text}</Paragraph>
            </ListItem>
            <ListItem>
              <Heading level={4}>{textMap.applyBullet2}</Heading>
              <Paragraph marginBottom={2}>{textMap.applyBullet2Text}</Paragraph>
              <Paragraph hint>{textMap.applyBullet2Hint}</Paragraph>
            </ListItem>
            <ListItem>
              <Heading level={4}>{textMap.applyBullet3}</Heading>
              <Paragraph marginBottom={2}>{textMap.applyBullet3Text}</Paragraph>
            </ListItem>
          </OrderedList>
        </Section>

        <Section>
          <Heading accentBar level={2}>
            {textMap.helpHeading}
          </Heading>
          <Paragraph>{textMap.helpParagraph}</Paragraph>

          <div className={getMarginBottomClass(6)}>
            <CustomerCareContent />
          </div>

          <Button elementType="a" href={dashboardUrl} onKeyDown={onCTAKeyDown}>
            {textMap.cta}
          </Button>
        </Section>
      </FormLayout>
    </ScrollToTopWrapper>
  );
};

PreExistingCustomerDataErrorSegment.propTypes = {
  CustomerCareContent: PropTypes.func.isRequired,
  onCTAKeyDown: PropTypes.func,
  textMap: PropTypes.shape({
    applyBullet1: PropTypes.node,
    applyBullet1Text: PropTypes.node,
    applyBullet2: PropTypes.node,
    applyBullet2Hint: PropTypes.node,
    applyBullet2Text: PropTypes.node,
    applyBullet3: PropTypes.node,
    applyBullet3Text: PropTypes.node,
    applyHeading: PropTypes.node,
    applySubHeading: PropTypes.node,
    cta: PropTypes.node,
    heading: PropTypes.node,
    helpHeading: PropTypes.node,
    helpParagraph: PropTypes.node,
    leadParagraph: PropTypes.node,
    title: PropTypes.string,
  }),
};

PreExistingCustomerDataErrorSegment.defaultProps = {
  onCTAKeyDown: undefined,
  textMap: {
    applyBullet1: <>Book an appointment</>,
    applyBullet1Text: <>Choose when you&#39;d like to talk to your International Banking case manager.</>,
    applyBullet2: <>Complete your application by phone</>,
    applyBullet2Hint: <></>,
    applyBullet2Text: (
      <>We&#39;ll ask you for your personal, contact and financial details. This will take about an hour.</>
    ),
    applyBullet3: <>Take your documents to a branch</>,
    applyBullet3Text: <>Your case manager will explain which documents you&#39;ll need to take with you.</>,
    applyHeading: <>How to apply</>,
    applySubHeading: <>Apply by phone</>,
    cta: <>Go to My accounts</>,
    heading: <>You&#39;ll need to continue your application by phone</>,
    helpHeading: <>How to book an appointment</>,
    helpParagraph: <>Please call us on the numbers below.</>,
    leadParagraph: (
      <>
        You can&#39;t complete your application online because of the type of account you&#39;re applying for, but one
        of our International Banking case managers can help you by phone.
      </>
    ),
    title: 'Application error',
  },
};

export default PreExistingCustomerDataErrorSegment;
