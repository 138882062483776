import { Translations } from '@icoz-frontends/translate';
import { marginBottomLevels } from '@piggybank/core';
import PropTypes from 'prop-types';
import React from 'react';
import { LockedDataKey, LockedDataList, LockedDataValue } from '../LockedDataList/LockedDataList';

const MaybeLockedOptionsField = ({ children, dataKey, isLocked, marginBottom, options, value }) =>
  isLocked ? (
    <LockedDataList marginBottom={marginBottom}>
      <LockedDataKey>{dataKey}</LockedDataKey>
      <LockedDataValue>
        <Translations translations={options[value]} />
      </LockedDataValue>
    </LockedDataList>
  ) : (
    children
  );

MaybeLockedOptionsField.defaultProps = {
  isLocked: false,
  marginBottom: 4,
  value: '',
};

MaybeLockedOptionsField.propTypes = {
  children: PropTypes.node.isRequired,
  dataKey: PropTypes.node.isRequired,
  isLocked: PropTypes.bool,
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  value: PropTypes.string,
};

export default MaybeLockedOptionsField;
