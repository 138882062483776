import deepmerge from 'deepmerge';

export const mergeConfigs = (...configs) =>
  deepmerge.all(configs.filter(Boolean), {
    arrayMerge: (_target, source) => [...source],
  });

export const mergeFormatters = (parentFormatters, childFormatters) => {
  if (parentFormatters && childFormatters) {
    return { ...parentFormatters, ...childFormatters };
  }

  return childFormatters || parentFormatters;
};

export const mergeIcoFetch = (parentFetch, childFetch) => {
  if (parentFetch && childFetch) {
    return Object.assign(parentFetch, childFetch);
  }

  return childFetch || parentFetch;
};
