import type { FeatureState } from './types';

export default (featureState: FeatureState, featureFlagOverrides?: string): FeatureState => {
  if (!featureFlagOverrides) {
    return featureState;
  }

  try {
    const overrides = JSON.parse(featureFlagOverrides) as FeatureState;
    return { ...featureState, ...overrides };
  } catch {
    return featureState;
  }
};
