import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import { object, string } from 'yup';
import { regexNumericWithCharacters } from '../../../utils/regex';

function isRequired(required) {
  return value => (!required ? true : !!value);
}

function isValidFormat(value) {
  if (!value) {
    return true;
  }

  if (value === 'invalid' || value === 'incomplete') {
    return false;
  }

  return parsePhoneNumberFromString(value)?.isValid() || false;
}

function isNumber(value) {
  return !value ? true : regexNumericWithCharacters().test(this.parent.raw);
}

export default ({ messages = {} }) => {
  const { invalid, matches, required } = messages;

  return object().shape({
    countryCode: string(),
    raw: string(),
    value: string()
      .test('required', required, isRequired(required))
      .test('valid', invalid, isValidFormat)
      .test('matches', matches, isNumber),
  });
};
