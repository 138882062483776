import { useEffect } from 'react';
import useLogger from './useLogger';

export default (...args) => {
  const logger = useLogger();

  useEffect(() => {
    logger(...args);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
