import { Spinner } from '@piggybank/core';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './index.module.css';

const Loading = ({ textMap }) => (
  <div aria-hidden className={styles.spinner}>
    <div>
      <Spinner />
    </div>
    <span>{textMap.loading}</span>
  </div>
);

Loading.propTypes = {
  textMap: PropTypes.shape({
    loading: PropTypes.node,
  }),
};

Loading.defaultProps = {
  textMap: {
    loading: <>Loading</>,
  },
};

export default Loading;
