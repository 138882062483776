import PropTypes from 'prop-types';
import React from 'react';
import styles from './index.module.css';

const LightText = ({ children }) => <span className={styles.lightText}>{children}</span>;

LightText.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LightText;
