import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { defaultTo } from 'lodash';

export default tel => {
  if (!tel || typeof tel !== 'string') {
    return '';
  }

  // Do not format masked numbers because the formatting
  // strips the asterisks from the formatted number.
  if (/\*+/.test(tel)) {
    return tel;
  }

  return defaultTo(parsePhoneNumberFromString(tel)?.formatInternational(), tel);
};
