import { useContext } from 'react';
import { CorridorAppsContext } from '../components/CorridorApps';

export default importKey => {
  const ctx = useContext(CorridorAppsContext);

  if (!importKey) {
    return ctx;
  }

  const { corridorImports, ...rest } = ctx;

  if (!corridorImports[importKey]) {
    throw new Error(`useCorridorApps: no corridor import key matches ${importKey}.`);
  }

  return {
    ...rest,
    corridorImports: corridorImports[importKey],
  };
};
