import { useConfig, useIsFeatureEnabled } from '@icoz-frontends/client';
import { ScheduledMaintenance as ScheduledMaintenanceCommon } from '@icoz-frontends/maintenance';
import { fflags } from '@icoz-frontends/shared';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

const ScheduledMaintenance = props => {
  const { endTimestamp, startTimestamp } = props;
  const isFeatureEnabled = useIsFeatureEnabled();
  const { routes } = useConfig();
  const location = useLocation();

  return (
    <ScheduledMaintenanceCommon
      enabled={isFeatureEnabled(fflags.SCHEDULED_MAINTENANCE) && location.pathname !== routes.maintenanceStatus}
      endTimestamp={endTimestamp}
      homeSlot={<Redirect to={routes.root} />}
      maintenanceSlot={<Redirect to={routes.maintenance} />}
      startTimestamp={startTimestamp}
    />
  );
};

ScheduledMaintenance.propTypes = {
  endTimestamp: PropTypes.number,
  startTimestamp: PropTypes.number,
};

ScheduledMaintenance.defaultProps = {
  endTimestamp: undefined,
  startTimestamp: undefined,
};

export default ScheduledMaintenance;
