import {
  Button,
  FormLayout,
  Heading,
  IconStatusErrorOnlight,
  Paragraph,
  Section,
  getMarginBottomClass,
} from '@piggybank/core';
import PropTypes from 'prop-types';
import React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import ScrollToTopWrapper from '../../components/ScrollToTopWrapper/ScrollToTopWrapper';
import useConfig from '../../hooks/useConfig';
import useLoggerEffect from '../../hooks/useLoggerEffect';
import styles from './ErrorPage.module.css';

const ErrorPage = ({ customerCareContent, dashboardUrl, finalSection, textMap }) => {
  const { logEventCodes } = useConfig();
  useLoggerEffect(logEventCodes.ERROR_PAGE);

  return (
    <ScrollToTopWrapper>
      <FormLayout>
        <PageTitle>{textMap.title}</PageTitle>

        <Section>
          <div className={styles.icon}>
            <IconStatusErrorOnlight scaled />
          </div>

          <Heading level={1}>{textMap.heading}</Heading>
          <Paragraph lead marginBottom={6}>
            {textMap.lead}
          </Paragraph>
        </Section>

        <Section>
          <Heading accentBar level={2}>
            {textMap.helpHeading}
          </Heading>
          <Paragraph marginBottom={6}>{textMap.helpParagraph}</Paragraph>
        </Section>
        <Section>
          <div className={getMarginBottomClass(6)}>{customerCareContent}</div>

          <Button elementType="a" href={dashboardUrl}>
            {textMap.button}
          </Button>
        </Section>

        {finalSection}
      </FormLayout>
    </ScrollToTopWrapper>
  );
};

ErrorPage.propTypes = {
  customerCareContent: PropTypes.node,
  dashboardUrl: PropTypes.string,
  finalSection: PropTypes.node,
  textMap: PropTypes.shape({
    button: PropTypes.node,
    heading: PropTypes.node,
    helpHeading: PropTypes.node,
    helpParagraph: PropTypes.node,
    lead: PropTypes.node,
    title: PropTypes.string,
  }),
};

ErrorPage.defaultProps = {
  customerCareContent: <></>,
  dashboardUrl: '#',
  finalSection: <></>,
  textMap: {
    button: <>Go to My accounts</>,
    heading: <>Something went wrong</>,
    helpHeading: <>What can I do?</>,
    helpParagraph: <>Try again later online or make an appointment to open your account by phone.</>,
    lead: <>We&#39;re sorry, we&#39;re unable to register your information at the moment.</>,
    title: 'Application error',
  },
};

export default ErrorPage;
