import { isPlainObject } from 'lodash';

export const isInboundCitizen = (values = {}) => {
  if (!isPlainObject(values)) {
    return false;
  }

  return values.citizenshipCode === values.inboundCountry || values.hasInboundResidency === 'yes';
};

export const hasInboundConnection = (values = {}) => {
  if (!isPlainObject(values)) {
    return false;
  }

  return (
    (values.hasAdditionalCitizenships === 'yes' && values.additionalCitizenships.includes(values.inboundCountry)) ||
    values.bornInInboundCountry === 'yes' ||
    values.hasInboundPassport === 'yes'
  );
};

export const isInboundPerson = (values = {}) => {
  if (!isPlainObject(values)) {
    return false;
  }

  return (
    isInboundCitizen(values) ||
    hasInboundConnection(values) ||
    values.hasInboundTaxResidency === 'yes' ||
    values.substantialPresence === 'yes'
  );
};
