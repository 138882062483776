import { Translations } from '@icoz-frontends/translate';
import {
  Button,
  FormLayout,
  Heading,
  HorizontalRule,
  IconStatusInformationOnlight,
  ListItem,
  Paragraph,
  Section,
  UnorderedList,
  getMarginBottomClass,
} from '@piggybank/core';
import PropTypes from 'prop-types';
import React from 'react';
import useConfig from '../../hooks/useConfig';
import useLoggerEffect from '../../hooks/useLoggerEffect';
import PageTitle from '../PageTitle/PageTitle';
import ScrollToTopWrapper from '../ScrollToTopWrapper/ScrollToTopWrapper';
import styles from './PreExistingCustomerData.module.css';
import createErrorMessages from './createErrorMessages';

const PreExistingCustomerDataErrorInvalid = ({ HowToUpdateDetails, errors, errorsTextMap, onCTAKeyDown, textMap }) => {
  const { dashboardUrl, logEventCodes } = useConfig();
  useLoggerEffect(logEventCodes.INVALID_DATA_ERROR);

  return (
    <ScrollToTopWrapper>
      <PageTitle>{textMap.title}</PageTitle>

      <FormLayout>
        <Section>
          <div className={`${styles.icon} ${getMarginBottomClass(3)}`}>
            <IconStatusInformationOnlight scaled />
          </div>
          <Heading level={1}>{textMap.heading}</Heading>
          <HorizontalRule />
        </Section>

        <Section>
          <Heading accentBar level={2} marginBottom={3}>
            {textMap.missing.heading}
          </Heading>
          <Paragraph>{textMap.missing.paragraph}</Paragraph>

          <UnorderedList role="list">
            {createErrorMessages(errors, errorsTextMap).map(item => (
              <ListItem key={item.key} marginBottom={0}>
                <Translations translations={item.message} />
              </ListItem>
            ))}
          </UnorderedList>
        </Section>

        <Section>
          <Heading accentBar level={2}>
            {textMap.howToUpdate.heading}
          </Heading>
          <Paragraph>{textMap.howToUpdate.paragraph}</Paragraph>

          <HowToUpdateDetails />

          <Button elementType="a" href={dashboardUrl} onKeyDown={onCTAKeyDown}>
            {textMap.cta}
          </Button>
        </Section>
      </FormLayout>
    </ScrollToTopWrapper>
  );
};

PreExistingCustomerDataErrorInvalid.propTypes = {
  HowToUpdateDetails: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      detail: PropTypes.string,
      jsonPath: PropTypes.string.isRequired,
      source: PropTypes.string,
    }),
  ),
  errorsTextMap: PropTypes.shape({}),
  onCTAKeyDown: PropTypes.func,
  textMap: PropTypes.shape({
    cta: PropTypes.node,
    heading: PropTypes.node,
    howToUpdate: {
      heading: PropTypes.node,
      paragraph: PropTypes.node,
    },
    missing: {
      heading: PropTypes.node,
      paragraph: PropTypes.node,
    },
    title: PropTypes.string,
  }),
};

PreExistingCustomerDataErrorInvalid.defaultProps = {
  errors: [],
  errorsTextMap: {},
  onCTAKeyDown: undefined,
  textMap: {
    cta: <>Go to My accounts</>,
    heading: <>We need you to update your profile</>,
    howToUpdate: {
      heading: <>How to update your profile</>,
      paragraph: (
        <>
          Your profile needs to be up to date before you can apply for this account. Please call us on the numbers
          below.
        </>
      ),
    },
    missing: {
      heading: <>What&#39;s missing</>,
      paragraph: (
        <>Your security is important to us so you&#39;ll need to update your details to continue this application.</>
      ),
    },
    title: 'Application error',
  },
};

export default PreExistingCustomerDataErrorInvalid;
