import {
  PreExistingCustomerDataContext,
  ScrollToTopWrapper,
  SessionKicker,
  useApiRequest,
  useConfig,
  useCorridorComponents,
  useIcoFetch,
  useIsFeatureEnabled,
  useSaveApplication,
} from '@icoz-frontends/client';
import { fflags } from '@icoz-frontends/shared';
import Translate from '@icoz-frontends/translate/macro';
import { FormLayout, Masthead } from '@piggybank/core';
import PropTypes from 'prop-types';
import React, { Suspense, useContext, useEffect, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import Processing from '../../components/Processing/Processing';

const sessionKickerTextMap = {
  modalButton: <Translate inline>sessionKicker.modal.link</Translate>,
  modalQuestion: <Translate>sessionKicker.modal.paragraph</Translate>,
  modalTitle: <Translate>sessionKicker.modal.heading</Translate>,
};

const WizardFlow = ({ applicationId, children, inboundCountry, productBasketData }) => {
  const { ProductBasket } = useCorridorComponents(inboundCountry, {
    importKey: 'main',
  });

  const icoMetadata = {
    headers: {
      icoApplicationId: applicationId,
      icoInbound: inboundCountry,
    },
  };

  const isFeatureEnabled = useIsFeatureEnabled();
  const config = useConfig();
  const { routes } = config;
  const icoFetch = useIcoFetch();
  const [onSave, , saveError, onContinue] = useSaveApplication(icoFetch.saveApplication(icoMetadata));
  const [onSubmit, submitLoading, submitError, submitData] = useApiRequest(icoFetch.submitApplication(icoMetadata));
  const shouldValidate = isFeatureEnabled(fflags.USE_FORM_VALIDATION);
  const { applicationState, customerData } = useContext(PreExistingCustomerDataContext);
  const isSessionKickerEnabled = useMemo(() => isFeatureEnabled(fflags.SESSION_TIMEOUT), [isFeatureEnabled]);

  useEffect(() => {
    if (!!submitData || !!submitError) {
      // assume form is complete or has encountered an issue it cannot recover from,
      // so don't add the confirmation message
      return undefined;
    }

    if (isFeatureEnabled(fflags.CONFIRM_LEAVE)) {
      const areYouSure = event => {
        event.preventDefault();

        const areYouSureMessage =
          'This page is asking you to confirm that you want to leave - data you have entered may not be saved.';
        // Chrome requires returnValue to be set. https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event

        event.returnValue = areYouSureMessage;
        return areYouSureMessage;
      };

      window.addEventListener('beforeunload', areYouSure);
      return () => window.removeEventListener('beforeunload', areYouSure);
    }

    return undefined;
  }, [submitData, submitError, isFeatureEnabled]);

  if (submitError) {
    return <Redirect to={{ pathname: routes.error, state: { corridorConfig: config, inboundCountry } }} />;
  }

  const isApplicationComplete = !!submitData?.applicationReference;
  const scrollToTopWrapperArgs = !isApplicationComplete ? { headerSlot: <Masthead marginBottom={0} /> } : {};

  return (
    <>
      <SessionKicker
        isEnabled={isSessionKickerEnabled}
        onGetServiceStatus={icoFetch.getServiceStatus(icoMetadata)}
        textMap={sessionKickerTextMap}
      >
        <ScrollToTopWrapper {...scrollToTopWrapperArgs}>
          {!isApplicationComplete && (
            <Suspense fallback={<span />}>
              <ProductBasket
                customerSegment={customerData.customerSegment}
                data={productBasketData}
                inboundCountry={inboundCountry}
              />
            </Suspense>
          )}
          <FormLayout>
            {children({
              applicationReference: submitData?.applicationReference,
              applicationState,
              applicationStatus: submitData?.applicationStatus,
              customerData,
              onContinue,
              onSave,
              onSubmit,
              saveError,
              shouldValidate,
            })}
          </FormLayout>
          <Processing isOnSubmit show={submitLoading} />
        </ScrollToTopWrapper>
      </SessionKicker>
    </>
  );
};

WizardFlow.propTypes = {
  applicationId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  inboundCountry: PropTypes.string.isRequired,
  productBasketData: PropTypes.shape({
    currentPage: PropTypes.number,
    productType: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default WizardFlow;
