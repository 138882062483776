import { tealiumTracking, useTealiumTracking } from '@icoz-frontends/client';

export default lockedInboundCountry => {
  const expanderEvent = {
    component: 'Expander',
    type: 'onOpen',
  };

  let funnelName = '';
  const isLocked = !!lockedInboundCountry;

  if (isLocked) {
    const corridor = lockedInboundCountry.toLowerCase();

    funnelName = `international customer onboarding-hk>${corridor === 'gb' ? 'uk' : corridor} corridor`;
  }

  useTealiumTracking(
    'Welcome',
    [
      tealiumTracking.trackView({
        funnel_name: funnelName,
        raw_datalayer: isLocked ? '3918v71' : '3918v1',
      }),
      tealiumTracking.trackEvent('accordion', 'other ways of applying', expanderEvent, {
        funnel_name: funnelName,
        raw_datalayer: isLocked ? '3918v72' : '3918v2',
      }),
    ],
    { asUnauthenticatedPage: true },
  );
};
