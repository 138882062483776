import merge from 'lodash/fp/merge';
import useConfig from '../useConfig';
import useTealiumTracking, { trackView } from './useTealiumTracking';

export default corridorConfig => {
  const { tealiumTracking: sharedConfig } = useConfig();
  const mergedConfig = merge(sharedConfig)(corridorConfig?.tealiumTracking || {});
  const title = mergedConfig?.events?.sessionTimedOutPage?.title || 'Timeout';
  const restData = mergedConfig?.events?.sessionTimedOutPage.restData || {};

  useTealiumTracking(title, [
    trackView(
      {
        ...restData,
        funnel_name: `${mergedConfig.funnelName}${mergedConfig.funnelCorridor}`,
      },
      true,
    ),
  ]);
};
