import { defaultTo } from 'lodash';
import { useMemo } from 'react';
import { retrieveDevSettings } from './storage';
import usePrevious from './usePrevious';

export default ({ isProd, root }) => {
  const enabled = useMemo(() => !isProd, []); // eslint-disable-line react-hooks/exhaustive-deps
  const update = usePrevious(true);

  return enabled && !!root && !update
    ? { ...defaultTo(retrieveDevSettings({ type: 'config' }), {}), devSettingsEnabled: true }
    : undefined;
};
