import { TransformJsonStringToJsx } from '@icoz-frontends/translate';
import { IconStatusErrorOnlight, Reveal, getMarginBottomClass, marginBottomLevels, withContext } from '@piggybank/core';
import { FieldContext, registerAsDescriber } from '@piggybank/form';
import cn from 'classnames';
import flowRight from 'lodash/fp/flowRight';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from './field-feedback.module.css';

/* eslint-disable */

class FieldFeedback extends Component {
  static propTypes = {
    iconAriaHidden: PropTypes.bool,
    textMap: PropTypes.shape({
      screenReaderPrefix: PropTypes.string,
    }),
    /**
     * 0, 1, 2, 3, 4, 5, 6, 7
     */
    marginBottom: PropTypes.oneOf(marginBottomLevels),
    marginTop: PropTypes.oneOf(marginBottomLevels),
    /** docgen-from-context:<Field/> */
    name: PropTypes.string.isRequired,
    /** docgen-from-context:<Field/> */
    children: PropTypes.node,
  };

  static defaultProps = {
    iconAriaHidden: false,
    textMap: {
      screenReaderPrefix: 'Error',
    },
    marginBottom: 0,
    marginTop: 2,
  };

  render() {
    const { iconAriaHidden, name, textMap, marginBottom, marginTop, children, ...rest } = this.props;

    return (
      <Reveal duration={150} marginTop={marginTop}>
        {children && (
          <div
            className={cn(styles['FieldFeedback'], getMarginBottomClass(marginBottom))}
            id={`${name}-feedback`}
            {...rest}
          >
            <div className={styles.container}>
              <span className={styles.icon}>
                <IconStatusErrorOnlight
                  aria-label={textMap.screenReaderPrefix}
                  aria-hidden={iconAriaHidden}
                  aria-describedby={`${name}-error-message`}
                  marginBottom={0}
                />
              </span>
              <span className={styles.message} id={`${name}-error-message`}>
                <TransformJsonStringToJsx jsonString={children} />
              </span>
            </div>
          </div>
        )}
      </Reveal>
    );
  }
}

export default flowRight(
  withContext( FieldContext, value => ({
    name: value.name,
    children: value.invalid && value.error,
  })),
  registerAsDescriber(({ name, invalid, error }) =>
    invalid && error ? `${name}-error-message` : undefined
  )
)(FieldFeedback);
