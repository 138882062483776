import { config as atlasConfig, cookie as atlasCookie, query as atlasQuery, isEnabled } from '@atlas/feature-flipper';
import Cookie from 'js-cookie';
import type { Envs } from '../types';
import combineSourcesSync from './combineSourcesSync';
import type { FeatureFlagsConfig, FeatureState, ParsedQuery } from './types';

export interface Params {
  config: FeatureFlagsConfig;
  cookieValue?: string;
  env: Envs;
  isCookieSourceEnabled: boolean;
  query?: ParsedQuery;
}

export type IsFeatureEnabled = ((feature: string) => boolean) & { featureState: FeatureState };

export default ({ config, cookieValue, env, isCookieSourceEnabled, query = {} }: Params): IsFeatureEnabled => {
  const featureState = combineSourcesSync(
    atlasConfig<Envs>(env, config),
    isCookieSourceEnabled && atlasCookie(cookieValue || Cookie.get('featureFlagOverrides') || ''),
    atlasQuery(query, 'feature_'),
  );

  const isFeatureEnabled = isEnabled(featureState) as IsFeatureEnabled;
  isFeatureEnabled.featureState = featureState;
  return isFeatureEnabled;
};
