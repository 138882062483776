import { isString } from 'lodash';

/**
 * Returns an array of "options" objects that have the values
 * specified in the `includedArray`.
 *
 * @param {Array<{label: String, value: String}>} options
 * @param {String[]} includedArray
 * @returns {Array<{label: String, value: String}>}
 */
export const optionsWith = (options, includedArray) => options.filter(x => includedArray.includes(x.value));

/**
 * Returns an array of "options" objects that do not have
 * the values specified in the `excludedArray`.
 *
 * @param {Array<{label: String, value: String}>} options
 * @param {String[]} excludedArray
 * @returns {Array<{label: String, value: String}>}
 */
export const optionsWithout = (options, excludedArray) => options.filter(x => !excludedArray.includes(x.value));

/**
 * @param {Array<{label: String, value: String}>} options
 * @param {String} value
 * @returns {String} - label or empty string
 */
export const getOptionLabelFromValue = (options, value) => {
  if (!options || !value) {
    return '';
  }

  const option = options.find(opt => opt.value === value);
  return option ? option.label : '';
};

/**
 * Returns an array of "options" objects with those
 * specified in the `valuesArray` at the head.
 *
 * @param {Array<{label: String, value: String}>} options
 * @param {String[]} valuesArray
 * @returns {Array<{label: String, value: String}>}
 */
export const moveOptionsToHead = (options, valuesArray) => {
  /**
   * Retrieve the options that we want to move to the head of
   * the array, maintaining the order specified in `valuesArray`.
   */
  const optionsToMove = optionsWith(options, valuesArray).sort(
    (a, b) => valuesArray.indexOf(a.value) - valuesArray.indexOf(b.value),
  );

  /**
   * Spreading `options` means we duplicate the `optionsToMove`
   * so that they are still discoverable in their original position
   * (when the option list is searched alphabetically). We create a
   * unique `key` for each `option` for use by `Select` components,
   * since `value` is no longer unique.
   */
  return [...optionsToMove, ...options].map((opt, i) => ({
    ...opt,
    key: `${opt.value}-${i}`,
  }));
};

/**
 * Transforms an options object into an array, ready for use with select inputs.
 *
 * @param {Object} optionsObject keyed by `option.value`
 * @returns {Array<{label: String, value: String, ...rest: Any }>}
 */
export const getOptionsArrayFromDomainShape = optionsObject =>
  Object.keys(optionsObject).reduce(
    (acc, key) => [
      ...acc,
      {
        // Spread option `label` and any additional meta data
        ...optionsObject[key],
        value: key,
      },
    ],
    [],
  );

/**
 * Sorts ascending array by elements property 'label'
 *
 * @param {Array<{label: String, ...rest: Any }>} array
 * @returns {Array<{label: String, ...rest: Any }>}
 */
export const sortByLabelAsc = array => array.sort((a, b) => a.label.localeCompare(b.label));

/**
 * @param {Object} optionsObject keyed by `option.value`
 * @returns {Array<String>}
 */
export const getValuesArrayFromDomainShape = optionsObject =>
  Object.keys(optionsObject).reduce((acc, key) => [...acc, key], []);

export const optionsTranslationsIntoDomainShape = optionsTranslations =>
  Object.keys(optionsTranslations).reduce((obj, key) => {
    const optionsTranslationsValue = optionsTranslations[key];

    const primaryTranslationsValue = isString(optionsTranslationsValue)
      ? optionsTranslationsValue
      : optionsTranslationsValue[0][1];

    obj[key] = {
      label: primaryTranslationsValue,
    };

    return obj;
  }, {});
