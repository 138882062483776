import PropTypes from 'prop-types';
import React from 'react';
import CorridorAppsContext from './context';

const CorridorAppsProvider = ({ children, componentNames, corridorImports, moduleNames }) => {
  const value = {
    componentNames,
    corridorImports,
    moduleNames,
  };

  return <CorridorAppsContext.Provider value={value}>{children}</CorridorAppsContext.Provider>;
};

CorridorAppsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  componentNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  corridorImports: PropTypes.objectOf(PropTypes.objectOf(PropTypes.func)).isRequired,
  moduleNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CorridorAppsProvider;
