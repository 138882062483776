import { boolean, lazy, object } from 'yup';

export default ({ addressType, addressValidationSchema, messages }) =>
  object().shape({
    isCorrespondenceAddressSameAsResidential: boolean().required(messages.noOptionSelected),
    correspondenceAddress: object().when(
      ['isCorrespondenceAddressSameAsResidential'],
      isCorrespondenceAddressSameAsResidential =>
        isCorrespondenceAddressSameAsResidential === false
          ? lazy(address => addressValidationSchema(address.country, addressType))
          : object(),
    ),
  });
