import PropTypes from 'prop-types';
import React from 'react';
import ErrorTypePage from './ErrorTypePage';
import GenericError from './GenericError';

const ErrorPage = ({ location }) => {
  const { corridorConfig, errorData, errorType, inboundCountry } = location?.state || {};

  if (!errorType || !inboundCountry) {
    return <GenericError corridorConfig={corridorConfig} />;
  }

  return (
    <ErrorTypePage corridorConfig={corridorConfig} data={errorData} inboundCountry={inboundCountry} type={errorType} />
  );
};

ErrorPage.defaultProps = {
  location: undefined,
};

ErrorPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      errorData: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          detail: PropTypes.string,
          jsonPath: PropTypes.string,
          source: PropTypes.string,
        }),
      ),
      errorType: PropTypes.string,
      inboundCountry: PropTypes.string,
    }),
  }),
};

export default ErrorPage;
