import { Reveal } from '@piggybank/core';
import { Hint, Legend, Radio, YesNoRadio } from '@piggybank/form';
import PropTypes from 'prop-types';
import React from 'react';
import FieldsetWithAnnounce from '../FieldsetWithAnnounce';
import FieldFeedback from '../piggybank/FieldFeedback/FieldFeedback';

const FieldsetWithYesNoReveal = ({
  announce,
  children,
  formBag: { values },
  hint,
  legend,
  marginBottom,
  name,
  onHide,
  required,
  reveal,
  revealOn,
  stackRadioInputs,
  yesNoTextMap,
}) => (
  <>
    <FieldsetWithAnnounce
      announce={announce}
      marginBottom={marginBottom}
      name={name}
      onChange={(next, rest) => {
        if (rest.value !== revealOn) {
          onHide();
        }

        next(rest);
      }}
      required={required}
    >
      <Legend>{legend}</Legend>
      {hint && <Hint>{hint}</Hint>}
      {stackRadioInputs ? (
        <>
          <Radio value>{yesNoTextMap.yes}</Radio>
          <Radio value={false}>{yesNoTextMap.no}</Radio>
        </>
      ) : (
        <YesNoRadio fullWidth textMap={yesNoTextMap} values={{ no: false, yes: true }} />
      )}
      <FieldFeedback />
    </FieldsetWithAnnounce>

    <Reveal {...reveal}>{values[name] === revealOn && children}</Reveal>
  </>
);

FieldsetWithYesNoReveal.defaultProps = {
  hint: undefined,
  marginBottom: 0,
  required: true,
  reveal: {
    marginBottom: 0,
  },
  stackRadioInputs: false,
};

FieldsetWithYesNoReveal.propTypes = {
  announce: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  formBag: PropTypes.shape({
    values: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
  hint: PropTypes.element,
  legend: PropTypes.element.isRequired,
  marginBottom: PropTypes.number,
  name: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  required: PropTypes.bool,
  reveal: PropTypes.shape({
    accentBar: PropTypes.bool,
    marginBottom: PropTypes.number,
    marginTop: PropTypes.number,
  }),
  revealOn: PropTypes.bool.isRequired,
  stackRadioInputs: PropTypes.bool,
  yesNoTextMap: PropTypes.shape({
    no: PropTypes.element.isRequired,
    yes: PropTypes.element.isRequired,
  }).isRequired,
};

export default FieldsetWithYesNoReveal;
