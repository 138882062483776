import { useLocation } from 'react-router-dom';

export default (enabledInboundCountries = []) => {
  const { pathname, search } = useLocation();

  // NOTE: This hook is only valid for the welcome
  // page which has no path.
  if (pathname !== '/') {
    return undefined;
  }

  if (enabledInboundCountries[0] === 'sg') {
    enabledInboundCountries[0] = 'gb';
  }

  const params = new URLSearchParams(search);
  let lockedInboundCountry = params.get('inboundCountry');

  if (lockedInboundCountry === null) {
    lockedInboundCountry = 'gb';
  }

  if (!lockedInboundCountry || !enabledInboundCountries.includes(lockedInboundCountry.toLowerCase())) {
    return undefined;
  }

  return lockedInboundCountry.toLowerCase();
};
