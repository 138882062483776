import { Heading, Section, VisuallyHidden } from '@piggybank/core';
import { Fieldset, Legend } from '@piggybank/form';
import PropTypes from 'prop-types';
import React from 'react';
import FieldsetWithYesNoReveal from '../../FieldsetWithYesNoReveal';

const Component = ({ contentMap, formBag, initialValues, textMap }) => {
  const { setFieldValue, values } = formBag;

  return (
    <Section>
      <Heading accentBar level={2}>
        {contentMap.heading}
      </Heading>

      <FieldsetWithYesNoReveal
        announce={textMap.announceLegend}
        formBag={{ values }}
        hint={contentMap.hint}
        legend={contentMap.legend}
        marginBottom={1}
        name="isCorrespondenceAddressSameAsResidential"
        onHide={() => {
          setFieldValue('correspondenceAddress', initialValues.correspondenceAddress);
        }}
        reveal={{
          accentBar: true,
          marginTop: 3,
        }}
        revealOn={false}
        stackRadioInputs
        yesNoTextMap={{
          no: contentMap.optionNo,
          yes: contentMap.optionYes,
        }}
      >
        <Fieldset marginBottom={0}>
          <Legend>
            <VisuallyHidden>{contentMap.detailsLegend}</VisuallyHidden>
          </Legend>
          {contentMap.addressFields({
            country: values.correspondenceAddress?.country,
            onChange: (next, rest) => {
              setFieldValue('correspondenceAddress', {
                ...initialValues.correspondenceAddress,
                country: rest.value,
              });

              next(rest);
            },
            path: 'correspondenceAddress',
          })}
        </Fieldset>
      </FieldsetWithYesNoReveal>
    </Section>
  );
};

Component.defaultProps = {
  textMap: {},
};

Component.propTypes = {
  contentMap: PropTypes.shape({
    addressFields: PropTypes.func.isRequired,
    detailsLegend: PropTypes.element.isRequired,
    heading: PropTypes.element.isRequired,
    hint: PropTypes.element.isRequired,
    legend: PropTypes.element.isRequired,
    optionNo: PropTypes.element.isRequired,
    optionYes: PropTypes.element.isRequired,
  }).isRequired,
  formBag: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setValues: PropTypes.func,
    values: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
  textMap: PropTypes.shape({
    announceLegend: PropTypes.string,
  }),
};

export default Component;
