import { Heading } from '@piggybank/core';
import { Fieldset, Legend } from '@piggybank/form';
import PropTypes from 'prop-types';
import React from 'react';
import Feature from './Feature';

const propTypes = {
  descriptions: PropTypes.objectOf(PropTypes.string).isRequired,
  features: PropTypes.objectOf(PropTypes.bool).isRequired,
  name: PropTypes.string.isRequired,
};

const FeatureSet = ({ descriptions, features, name }) => (
  <>
    <Heading level={2} style={{ marginTop: 'var(--unit4)' }}>
      {name}
    </Heading>
    <Fieldset marginBottom={4} name={name}>
      <Legend>{descriptions[name]}</Legend>
      {Object.keys(features).map(key => (
        <Feature description={descriptions[key]} key={key} name={key} />
      ))}
    </Fieldset>
  </>
);

FeatureSet.propTypes = propTypes;

export default FeatureSet;
