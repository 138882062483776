import { Heading, Loading, Paragraph } from '@piggybank/core';
import PropTypes from 'prop-types';
import React from 'react';

const LoadingPage = ({ show, textMap, ...rest }) => (
  <Loading focusDialog={false} getApplicationNode={() => false} initialFocus="#root" show={show} {...rest}>
    <Heading level={3}>{textMap.loading}</Heading>
    <Paragraph marginBottom={0}>{textMap.info}</Paragraph>
  </Loading>
);

LoadingPage.propTypes = {
  show: PropTypes.bool.isRequired,
  textMap: PropTypes.shape({
    info: PropTypes.node,
    loading: PropTypes.node,
  }),
};

LoadingPage.defaultProps = {
  textMap: {
    info: <>Please wait</>,
    loading: <>Loading</>,
  },
};

export default LoadingPage;
