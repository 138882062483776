import snakeCase from 'lodash/fp/snakeCase';

export const buildRouteSuffix = (route?: string): string => (route ? `_${snakeCase(route).toUpperCase()}` : '');

export const conditionallyParseResponseBody = async (
  res: Response,
  statusCodes: number[] = [],
  isProd = true,
): Promise<string | undefined> => {
  if (!isProd || statusCodes.includes(res.status)) {
    const cloneRes = res.clone();
    return cloneRes.text();
  }

  return undefined;
};

export const defaultHeadersBlacklist = [
  'authorization',
  'client_id',
  'client_secret',
  'set-cookie',
  'x-authtoken',
  'x-hsbc-dsp-comm',
];

export const defaultResponseBodyWhitelist = [400, 403, 500];

export const filterHeaders = (
  headers: Record<string, string> = {},
  excludeHeaders: string[] = [],
  isProd = true,
): Record<string, string> =>
  !isProd
    ? headers
    : Object.keys(headers).reduce((santized, key) => {
        if (!excludeHeaders.includes(key.toLowerCase())) {
          santized[key] = headers[key] as string;
        }

        return santized;
      }, {} as Record<string, string>);

export const formatErrors = (errors: Error | Error[]): Error[] => {
  if (!errors) {
    return [];
  }

  return Array.isArray(errors) ? errors : [errors];
};

export const formatResponseHeaders = (headers: Headers): Record<string, string> =>
  Array.from(headers.entries()).reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
