import { LoadingPage as LoadingPageCommon } from '@icoz-frontends/client';
import Translate from '@icoz-frontends/translate/macro';
import PropTypes from 'prop-types';
import React from 'react';

const textMap = {
  info: <Translate>loadingPage.paragraph</Translate>,
  loading: <Translate>loadingPage.heading</Translate>,
};

const LoadingPage = ({ show }) => <LoadingPageCommon show={show} textMap={textMap} />;

LoadingPage.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default LoadingPage;
