import { fflags } from '@icoz-frontends/shared';
import { useMachine } from '@xstate/react';
import { useRef } from 'react';
import saveApplicationMachine, { FAILURE, RESET, SAVE, SAVING, SUCCESS } from '../stateMachines/saveApplicationMachine';
import useIsFeatureEnabled from './useIsFeatureEnabled';

const setErrorViewed = errorViewed => {
  errorViewed.current = true;
};

const hasErrorBeenViewed = errorViewed => !!errorViewed.current;

const setOnSaveParams = (onSaveParams, { data, next }) => {
  onSaveParams.current.data = data;
  onSaveParams.current.next = next;
};

const unsetOnSaveParams = onSaveParams => {
  onSaveParams.current.data = undefined;
  onSaveParams.current.next = undefined;
};

export default saveApplication => {
  const [current, send] = useMachine(saveApplicationMachine);
  const isFeatureEnabled = useIsFeatureEnabled();
  const errorViewed = useRef(false);
  const onSaveParams = useRef({});

  const onSave = async (next, data) => {
    const { values } = data;

    if (!isFeatureEnabled(fflags.SAVE_AND_RETRIEVE)) {
      next(data);
      return;
    }

    try {
      send(SAVE);
      await saveApplication(values);
      send(SUCCESS);
      next(data);
    } catch (e) {
      send(FAILURE);

      if (hasErrorBeenViewed(errorViewed)) {
        next(data);
      } else {
        setOnSaveParams(onSaveParams, { data, next });
      }
    } finally {
      send(RESET);
    }
  };

  const onContinue = () => {
    const { data, next } = onSaveParams.current;
    setErrorViewed(errorViewed);
    next(data);
    unsetOnSaveParams(onSaveParams);
  };

  return [
    onSave,
    current.matches(SAVING),
    current.context.errors === 1 && !hasErrorBeenViewed(errorViewed),
    onContinue,
    current.value,
  ];
};
