import { useMemo } from 'react';
import extendedLazy from '../utils/extendedLazy';
import useCorridorApps from './useCorridorApps';

export default (inboundCountry, { importKey } = {}) => {
  const { corridorImports, componentNames = [] } = useCorridorApps(importKey);

  const corridorComponents = useMemo(
    () =>
      componentNames.reduce((components, componentName) => {
        components[componentName] = extendedLazy(corridorImports[inboundCountry], componentName);
        return components;
      }, {}),
    [inboundCountry, importKey], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return corridorComponents;
};
