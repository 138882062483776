import merge from 'lodash/fp/merge';
import useConfig from '../useConfig';
import useTealiumTracking, { trackView } from './useTealiumTracking';

export default corridorConfig => {
  const { tealiumTracking: sharedConfig } = useConfig();
  const mergedConfig = merge(sharedConfig)(corridorConfig?.tealiumTracking || {});
  const title = mergedConfig?.events?.uploadError?.title || 'Upload error';

  const data = {
    ...(mergedConfig?.events?.uploadError?.restData || {}),
    funnel_name: `${mergedConfig.funnelName}${mergedConfig.funnelCorridor}`,
  };

  useTealiumTracking(title, [trackView({ ...data }, true)]);
};
