import makeEvent from './event';

export default (pageName, action, label, value, asUnauthenticatedPage, config, rest = {}) =>
  makeEvent(
    pageName,
    action,
    `${label.toLowerCase()}: ${value.toString().toLowerCase()}`,
    asUnauthenticatedPage,
    config,
    rest,
  );
