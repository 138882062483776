import { buildHeadersObject } from '@icoz-frontends/shared';

export default (splunkEndpoint, isProd = true, headers = {}) => {
  if (!splunkEndpoint) {
    throw new Error('No endpoint provided');
  }

  return (logEventCode, metadata, logLevel) => {
    const body = { code: logEventCode.code };

    if (metadata) {
      body.metadata = metadata;
    }

    if (logLevel) {
      body.logLevel = logLevel;
    }

    if (!isProd && logEventCode.error_code) {
      if (logEventCode.error_code.endsWith('ERROR') || logEventCode.error_code.endsWith('EXCEPTION')) {
        console.error(logEventCode.error_code, metadata); // eslint-disable-line no-console
      }
    }

    return fetch(splunkEndpoint, {
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        ...buildHeadersObject(headers),
      },
      method: 'POST',
    })
      .then(res => {
        if (!res.ok) {
          /**
           * Force execution into the `catch` block if we do
           * not get a successful response status (200-299).
           */
          throw new Error(res.statusText);
        }
      })
      .catch(e => {
        console.warn(`Failed to send client event ${logEventCode}`, e); // eslint-disable-line no-console
      });
  };
};
