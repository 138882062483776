import { Heading, PlaybackKey, PlaybackList, PlaybackValue } from '@piggybank/core';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formatAddress, formatDate } from '../../../dataFormatters';
import { deriveWhereDidYouLiveBeforeDate } from './utils';

const Review = ({ contentMap, customerData, max, values }) => {
  let previousResidentialAddresses = [];

  if (values.previousResidentialAddresses.length) {
    previousResidentialAddresses = values.previousResidentialAddresses;
  } else if (customerData.previousResidentialAddresses?.length) {
    previousResidentialAddresses = customerData.previousResidentialAddresses;
  }

  return previousResidentialAddresses.length ? (
    <>
      <Heading component="h3" level={4}>
        {contentMap.heading}
      </Heading>

      <PlaybackList type="review">
        {previousResidentialAddresses.slice(0, max).map((address, index) => {
          const formattedAddress = formatAddress(address);

          return (
            <Fragment key={`previous-addresss-${btoa(formattedAddress)}`}>
              <PlaybackKey>
                {contentMap.whereDidYouLiveBefore(
                  formatDate(deriveWhereDidYouLiveBeforeDate(values, index), 'MM/YYYY'),
                )}
              </PlaybackKey>
              <PlaybackValue>{formattedAddress}</PlaybackValue>

              <PlaybackKey>{contentMap.whenDidYouMoveIn}</PlaybackKey>
              <PlaybackValue>{formatDate(address.startDate, 'MM/YYYY')}</PlaybackValue>
            </Fragment>
          );
        })}
      </PlaybackList>
    </>
  ) : null;
};

Review.defaultProps = {
  customerData: {},
  max: 3,
};

Review.propTypes = {
  contentMap: PropTypes.shape({
    heading: PropTypes.element.isRequired,
    whenDidYouMoveIn: PropTypes.element.isRequired,
    whereDidYouLiveBefore: PropTypes.func.isRequired,
  }).isRequired,
  customerData: PropTypes.objectOf(PropTypes.any),
  max: PropTypes.number,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Review;
