import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

const propTypes = {
  children: PropTypes.string.isRequired,
};

const PageTitle = ({ children }) => (
  <Helmet>
    <title>{`${children} HSBC International Services Application`}</title>
  </Helmet>
);

PageTitle.propTypes = propTypes;

export default PageTitle;
