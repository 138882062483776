import { array, lazy, object } from 'yup';

export default ({ addressType, addressValidationSchema, shouldAskForPreviousAddresses }) =>
  object().shape({
    previousResidentialAddresses: array().when(
      ['currentResidentialAddressStartDate', '_dateOfBirth'],
      (currentResidentialAddressStartDate, dateOfBirth) =>
        shouldAskForPreviousAddresses(currentResidentialAddressStartDate)
          ? array().of(
              lazy(address =>
                addressValidationSchema(address.country, addressType, {
                  dateOfBirth,
                  startDateBefore: currentResidentialAddressStartDate,
                }),
              ),
            )
          : array(),
    ),
  });
