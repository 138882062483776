import { config as sourceConfig } from '@atlas/feature-flipper';
import { fflags } from '@icoz-frontends/shared';
import { Button, ButtonRow, FormLayout, Heading } from '@piggybank/core';
import { Form } from '@piggybank/form';
import isEmpty from 'lodash/fp/isEmpty';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ScrollToTopWrapper from '../../components/ScrollToTopWrapper/ScrollToTopWrapper';
import FeatureSet from './FeatureSet';
import { readCookie, removeCookie, writeCookie } from './cookies';
import { assignDefaults, groupOptions, omitDefaults } from './utils';

const FeatureFlipperPage = ({ config, cookieName, env, onReset, onSubmit, redirectRoute }) => {
  const [descriptions, setDescriptions] = useState();
  const [defaultOptions, setDefaultOptions] = useState();
  const [options, setOptions] = useState();

  useEffect(() => {
    setDescriptions(config.reduce((acc, feature) => ({ ...acc, [feature.label]: feature.description }), {}));
    const defaults = fflags.combineSourcesSync(sourceConfig(env, config));
    const opts = assignDefaults(defaults, readCookie(cookieName));
    setDefaultOptions(defaults);
    setOptions(opts);
  }, [config, cookieName, env]);

  const redirect = () => {
    window.location.href = redirectRoute; // redirect with refresh
  };

  if (!options) return null;

  const groupedOptions = groupOptions(options);

  return (
    <ScrollToTopWrapper>
      <FormLayout>
        <Heading level={1}>{env} feature flipper</Heading>

        <Form
          initialValues={options}
          onSubmit={({ values }) => {
            const newOptions = omitDefaults(defaultOptions, values);

            if (isEmpty(newOptions)) {
              removeCookie(cookieName);
            } else {
              writeCookie(cookieName, newOptions);
            }

            if (onSubmit) {
              onSubmit();
            }

            redirect();
          }}
        >
          {Object.keys(groupedOptions).map(key => (
            <FeatureSet descriptions={descriptions} features={groupedOptions[key]} key={key} name={key} />
          ))}

          <ButtonRow>
            <Button type="submit">Apply</Button>
            <Button
              onClick={() => {
                removeCookie(cookieName);

                if (onReset) {
                  onReset();
                }

                redirect();
              }}
              type="reset"
            >
              Reset
            </Button>
          </ButtonRow>
        </Form>
      </FormLayout>
    </ScrollToTopWrapper>
  );
};

FeatureFlipperPage.defaultProps = {
  onReset: undefined,
  onSubmit: undefined,
  redirectRoute: '/',
};

FeatureFlipperPage.propTypes = {
  config: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      enabled: PropTypes.objectOf(PropTypes.bool).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  cookieName: PropTypes.string.isRequired,
  env: PropTypes.string.isRequired,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  redirectRoute: PropTypes.string,
};

export default FeatureFlipperPage;
