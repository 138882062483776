import { Heading, Loading, Paragraph } from '@piggybank/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import PageTitle from '../PageTitle/PageTitle';

/* eslint-disable react/require-default-props, react/prop-types, react/destructuring-assignment */
/* Please fix linting errors next time file is touched */

const Inner = ({ tealiumTracking, texts }) => {
  if (tealiumTracking) {
    tealiumTracking();
  }

  return (
    <>
      <PageTitle>{texts.title}</PageTitle>
      <Heading level={3}>{texts.heading}</Heading>
      <Paragraph marginBottom={0}>{texts.content}</Paragraph>
    </>
  );
};

const Processing = props => (
  <Loading data-testid="Processing" {...props}>
    <Inner tealiumTracking={props.tealiumTracking} texts={props.texts} />
  </Loading>
);

Processing.propTypes = {
  show: PropTypes.bool.isRequired,
  tealiumTracking: PropTypes.func,
  texts: PropTypes.shape({
    content: PropTypes.node,
    heading: PropTypes.node,
    title: PropTypes.string,
  }),
};

Processing.defaultProps = {
  texts: {
    content: <>Please don&#39;t refresh your browser or use the back button</>,
    heading: <>Processing your application</>,
    title: 'Processing your application',
  },
};

export default Processing;
