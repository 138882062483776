import {
  IcoToolbelt,
  PreExistingCustomerData as PreExistingCustomerDataCommon,
  isFunction,
  useApiRequest,
  useConfig,
  useIcoFetch,
  useIsFeatureEnabled,
} from '@icoz-frontends/client';
import { fflags } from '@icoz-frontends/shared';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import LoadingPage from '../../../components/LoadingPage';

const PreExistingCustomerData = ({ applicationId, children, corridorConfig, inboundCountry, productBasketData }) => {
  const { fakeCin } = useConfig();
  const isFeatureEnabled = useIsFeatureEnabled();

  useEffect(() => {
    if (isFeatureEnabled(fflags.USE_STUBS) && isFunction(URL)) {
      const url = new URL(window.location.href);

      if (!url.searchParams.has('cin')) {
        /**
         * Add a `cin` param to the url. The `cin` is used on the BE to match
         * a set of stubs. The API returns an error if it is not provided.
         */
        url.searchParams.set('cin', fakeCin);
        window.history.replaceState({}, '', url);
      }
    }
  }, [fakeCin, isFeatureEnabled]);

  const icoFetch = useIcoFetch();

  const [getData, , error, data] = useApiRequest(
    icoFetch.getApplicationState({
      headers: {
        icoApplicationId: applicationId,
        icoInbound: inboundCountry,
      },
    }),
  );

  useEffect(() => {
    getData();
  }, [applicationId, inboundCountry]); // eslint-disable-line react-hooks/exhaustive-deps

  const customerSegment = data?.customerData?.customerSegment;
  const funnelCorridor = corridorConfig?.tealiumTracking?.funnelCorridor;

  return (
    <IcoToolbelt
      config={{
        customerSegment,
        tealiumTracking: {
          ...(funnelCorridor ? { funnelCorridor } : {}),
          applicationId,
          customerSegment,
          productType: productBasketData.productType ? productBasketData.productType[0] : '',
        },
      }}
    >
      <PreExistingCustomerDataCommon
        LoadingPage={LoadingPage}
        data={data}
        error={error}
        inboundCountry={inboundCountry}
      >
        {children}
      </PreExistingCustomerDataCommon>
    </IcoToolbelt>
  );
};

PreExistingCustomerData.defaultProps = {
  corridorConfig: undefined,
};

PreExistingCustomerData.propTypes = {
  applicationId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  corridorConfig: PropTypes.objectOf(PropTypes.any),
  inboundCountry: PropTypes.string.isRequired,
  productBasketData: PropTypes.shape({
    productType: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default PreExistingCustomerData;
