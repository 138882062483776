import {
  FieldWithAnnounce,
  Loading,
  optionUtils,
  useConfig,
  useCorridorComponents,
  useInboundCountryLock,
  useIsFeatureEnabled,
} from '@icoz-frontends/client';
import { markets } from '@icoz-frontends/data';
import Translate from '@icoz-frontends/translate/macro';
import { Reveal } from '@piggybank/core';
import { Label, Select } from '@piggybank/form';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import WelcomeFallbackContent from './WelcomeFallbackContent';

const WelcomeContent = ({ values }) => {
  let inboundCountry = values.inboundCountry.toUpperCase();
  const { corridor } = useConfig();
  const isFeatureEnabled = useIsFeatureEnabled();

  const INBOUND_COUNTRIES = corridor.availableInboundCountries.map(
    country => isFeatureEnabled(`CORRIDORS:${country.abbrev.toUpperCase()}`) && country.abbrev,
  );

  const lockedInboundCountry = useInboundCountryLock(INBOUND_COUNTRIES);

  if (lockedInboundCountry) {
    inboundCountry = lockedInboundCountry.toUpperCase();
  }

  const { WelcomeProgressContent } = useCorridorComponents(inboundCountry, {
    importKey: 'welcome',
  });

  const OUTBOUND_COUNTRY = corridor.outboundCountry.abbrev;

  const MARKET_OPTIONS = optionUtils.sortByLabelAsc(
    Object.values(markets)
      .filter(({ abbrev }) => abbrev !== OUTBOUND_COUNTRY)
      .map(({ abbrev, name }) => ({
        label: name,
        value: abbrev,
      })),
  );

  const corridorFeatureFlagName = `CORRIDORS:${inboundCountry}`;
  let isEnabled;

  try {
    isEnabled = isFeatureEnabled(corridorFeatureFlagName);
  } catch {
    isEnabled = false;
  }

  const inboundCountryTranslate = lockedInboundCountry ? (
    <Translate isLabel>welcomePage.inboundCountry.locked.heading</Translate>
  ) : (
    <Translate isLabel>welcomePage.inboundCountry.heading</Translate>
  );

  const announce = lockedInboundCountry
    ? Translate('welcomePage.inboundCountry.locked.heading', { locale: 'zh-hant' })
    : Translate('welcomePage.inboundCountry.heading', { locale: 'zh-hant' });

  return (
    <>
      <FieldWithAnnounce announce={announce} name="inboundCountry">
        <Label>{inboundCountryTranslate}</Label>
        <Select
          disabled={!!lockedInboundCountry}
          options={optionUtils.moveOptionsToHead(MARKET_OPTIONS, INBOUND_COUNTRIES)}
        />
      </FieldWithAnnounce>
      {!!inboundCountry && (
        <>
          <Reveal>
            {isEnabled && (
              <Suspense fallback={<Loading textMap={{ loading: <Translate inline>common.loading</Translate> }} />}>
                <WelcomeProgressContent inboundCountry={inboundCountry} />
              </Suspense>
            )}
          </Reveal>
          <Reveal>{!isEnabled && <WelcomeFallbackContent />}</Reveal>
        </>
      )}
    </>
  );
};

WelcomeContent.propTypes = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default WelcomeContent;
