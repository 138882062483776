import isEmptyDropdown from './isEmptyDropdown';

export default (field, values) => {
  try {
    if (isEmptyDropdown(field, values)) {
      return false;
    }

    return field.selector(values) !== undefined;
  } catch (e) {
    // if the selector errors, don't send the event.
    console.error(`could not send form value to Tealium`); // eslint-disable-line no-console
    return false;
  }
};
