import { Button, FormLayout, Heading, Paragraph, Section } from '@piggybank/core';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollToTopWrapper from '../../components/ScrollToTopWrapper/ScrollToTopWrapper';
import useConfig from '../../hooks/useConfig';
import useLoggerEffect from '../../hooks/useLoggerEffect';

const SessionTimedOutPage = ({ restartRoute, textMap }) => {
  const { logEventCodes } = useConfig();
  useLoggerEffect(logEventCodes.SESSION_TIMEOUT);

  return (
    <ScrollToTopWrapper>
      <FormLayout>
        <Section>
          <Heading level={1}>{textMap.heading}</Heading>
          <Paragraph lead>{textMap.lead}</Paragraph>
          <Button elementType="a" href={restartRoute}>
            {textMap.cta}
          </Button>
        </Section>
      </FormLayout>
    </ScrollToTopWrapper>
  );
};

SessionTimedOutPage.propTypes = {
  restartRoute: PropTypes.string.isRequired,
  textMap: PropTypes.shape({
    cta: PropTypes.node,
    heading: PropTypes.node,
    lead: PropTypes.node,
  }),
};

SessionTimedOutPage.defaultProps = {
  textMap: {
    cta: <>Restart application</>,
    heading: <>Application timeout</>,
    lead: <>Due to inactivity your application has been timed out. Please return to HSBC or restart the application.</>,
  },
};

export default SessionTimedOutPage;
