import {
  CypressRemount,
  EnsureAuthentication,
  IcoToolbelt,
  createLogger,
  useApplicationId,
  useConfig,
  useCorridorComponents,
  useCorridorModules,
} from '@icoz-frontends/client';
import Cookie from 'js-cookie';
import PropTypes from 'prop-types';
import React, { Suspense, useRef } from 'react';
import LoadingPage from '../../components/LoadingPage';
import Processing from '../../components/Processing/Processing';
import PreExistingCustomerData from './PreExistingCustomerData';
import WizardFlow from './WizardFlow';

const AuthenticatedFlow = ({ history, match }) => {
  const { expressHost, routes } = useConfig();
  const inboundCountry = match.params.inboundCountry.toUpperCase();
  const applicationId = useApplicationId(inboundCountry);

  const { Corridor } = useCorridorComponents(inboundCountry, {
    importKey: 'main',
  });

  const productBasketDataRef = useRef({
    currentPage: 0,
    productType: undefined,
  });

  const { config } = useCorridorModules(inboundCountry, { importKey: 'main' });

  return (
    <IcoToolbelt
      createLogger={(splunkRoute, isProd) =>
        createLogger(splunkRoute, isProd, { icoApplicationId: applicationId, icoInbound: inboundCountry })
      }
    >
      <EnsureAuthentication
        LoadingPage={LoadingPage}
        applicationId={applicationId}
        authRoute={routes.auth}
        expressHost={expressHost}
        inboundCountry={inboundCountry}
        isAuthenticated={!!window.Cypress || Cookie.get('authenticated') === 'true'}
      >
        <PreExistingCustomerData
          applicationId={applicationId}
          corridorConfig={config}
          inboundCountry={inboundCountry}
          productBasketData={productBasketDataRef.current}
        >
          <CypressRemount enabled={!!window.Cypress}>
            <WizardFlow
              applicationId={applicationId}
              inboundCountry={inboundCountry}
              productBasketData={productBasketDataRef.current}
            >
              {renderProps => (
                <Suspense fallback={<Processing getApplicationNode={() => false} show />}>
                  <Corridor {...renderProps} history={history} productBasketDataRef={productBasketDataRef} />
                </Suspense>
              )}
            </WizardFlow>
          </CypressRemount>
        </PreExistingCustomerData>
      </EnsureAuthentication>
    </IcoToolbelt>
  );
};

AuthenticatedFlow.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AuthenticatedFlow;
