import { useTranslate } from '@icoz-frontends/translate';
import { Field } from '@piggybank/form';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

const FieldWithAnnounce = ({ announce, ...rest }) => {
  const { updateAnnouncement } = useTranslate();

  const handleKeyDown = useCallback(
    event => {
      updateAnnouncement(event, announce);
    },
    [announce], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return <Field {...rest} onKeyDown={handleKeyDown} />;
};

FieldWithAnnounce.propTypes = {
  announce: PropTypes.string.isRequired,
};

export default FieldWithAnnounce;
