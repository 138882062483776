import { marginBottomLevels } from '@piggybank/core';
import { Field, Fieldset } from '@piggybank/form';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';

/* eslint-disable react/require-default-props, react/forbid-prop-types, react/prop-types */
/* Please fix linting errors next time file is touched */

const propTypes = {
  asFieldset: PropTypes.bool,
  children: PropTypes.node,
  // copy-paste from @piggybank/form/Field
  debounceWait: PropTypes.number,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  invalid: PropTypes.bool,
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  timestampName: PropTypes.string,
  touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  value: PropTypes.any,
};

const TimeTrackingField = ({ asFieldset, formik, onChange, timestampName, ...props }) => {
  const { name } = props;

  const wrappedOnChange = (next, rest) => {
    const timestamp = rest.value ? DateTime.utc().startOf('second').toISO() : '';
    const tsName = timestampName || `${name}Timestamp`;
    formik.setFieldValue(tsName, timestamp);

    return onChange ? onChange(next, rest) : next(rest);
  };

  const Comp = asFieldset ? Fieldset : Field;

  return <Comp onChange={wrappedOnChange} {...props} />;
};

TimeTrackingField.propTypes = propTypes;

export default TimeTrackingField;
