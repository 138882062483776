import makeSharedPayload from './shared';

export default (pageName, action, content, asUnauthenticatedPage, config, rest = {}) => ({
  ...makeSharedPayload(pageName, asUnauthenticatedPage, config),
  application_event: rest.application_event || undefined,
  event_action: action.toLowerCase(),
  event_category: 'content',
  event_content: content.toLowerCase(),
  form_field_1: rest.form_field_1 || undefined,
  ...rest,
});
