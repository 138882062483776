import { marginBottomLevels } from '@piggybank/core';
import { AddAnother } from '@piggybank/form';
import PropTypes from 'prop-types';
import React from 'react';

/* eslint-disable react/require-default-props, react/forbid-prop-types, react/destructuring-assignment, no-unused-vars, max-len */
/* Please fix linting errors next time file is touched */

const propTypes = {
  addInfoSlot: PropTypes.node,
  children: PropTypes.func.isRequired,
  duration: PropTypes.number,
  easing: PropTypes.string,
  focusFirstInput: PropTypes.bool,
  formik: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  itemInitialValue: PropTypes.any.isRequired,
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  max: PropTypes.number,
  min: PropTypes.number,
  minOnMount: PropTypes.number,
  // copy-pasted from @piggybank/form/AddAnother
  name: PropTypes.string.isRequired,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  renderAddButtonText: PropTypes.func,
  renderRemoveButtonText: PropTypes.func,
  shouldShowRemoveButton: PropTypes.func,
  showAdd: PropTypes.bool,
  textMap: PropTypes.shape({
    addButton: PropTypes.node,
    removeButton: PropTypes.node,
  }),
};

/**
 * This is a more convenient API extension for AddAnother, with the following additions:
 * - `currentItem` prop passed to children
 * - `otherItems` prop passed to children
 * It also ensures that `minOnMount` falls back on `min` if absent.
 */
const ExtendedAddAnother = ({ children, formik, minOnMount, ...props }) => {
  const formikValues = (formik && formik.values && formik.values[props.name]) || [];
  const saneMinOnMount = minOnMount === null || minOnMount === undefined ? props.min : minOnMount;

  return (
    <AddAnother minOnMount={saneMinOnMount} {...props}>
      {({ index, path }) =>
        children({
          currentItem: formikValues[index] || {},
          index,
          otherItems: formikValues.filter((v, i) => i !== index),
          path,
        })
      }
    </AddAnother>
  );
};

ExtendedAddAnother.propTypes = propTypes;

export default ExtendedAddAnother;
