import { Heading, Paragraph, getMarginBottomClass } from '@piggybank/core';
import PropTypes from 'prop-types';
import React from 'react';
import LightText from '../LightText';

const BankingCenterContactDetails = ({ contentMap, numberMap }) => (
  <>
    <Heading level={3} marginBottom={3}>
      {contentMap.heading}
    </Heading>
    {contentMap.hint && <Paragraph hint>{contentMap.hint}</Paragraph>}
    <Paragraph>{numberMap.local}</Paragraph>

    {contentMap.intlPhoneNumberHeading && numberMap.intl && (
      <dl className={getMarginBottomClass(4)}>
        <dt className={getMarginBottomClass(2)}>
          <LightText>{contentMap.intlPhoneNumberHeading}</LightText>
        </dt>
        <dd>{numberMap.intl}</dd>
      </dl>
    )}

    {contentMap.textPhoneHeading && numberMap.textPhoneLocal && (
      <>
        <Heading level={3} marginBottom={3}>
          {contentMap.textPhoneHeading}
        </Heading>
        {contentMap.textPhoneHint && <Paragraph hint>{contentMap.textPhoneHint}</Paragraph>}
        <Paragraph>{numberMap.textPhoneLocal}</Paragraph>
      </>
    )}

    {contentMap.textPhoneSubHeading && numberMap.textPhoneIntl && (
      <dl>
        <dt className={getMarginBottomClass(2)}>
          <LightText>{contentMap.textPhoneSubHeading}</LightText>
        </dt>
        <dd>{numberMap.textPhoneIntl}</dd>
      </dl>
    )}
  </>
);

BankingCenterContactDetails.propTypes = {
  contentMap: PropTypes.shape({
    heading: PropTypes.element.isRequired,
    hint: PropTypes.element,
    intlPhoneNumberHeading: PropTypes.element,
    textPhoneHeading: PropTypes.element,
    textPhoneHint: PropTypes.element,
    textPhoneSubHeading: PropTypes.element,
  }).isRequired,
  numberMap: PropTypes.shape({
    intl: PropTypes.string,
    local: PropTypes.string.isRequired,
    textPhoneIntl: PropTypes.string,
    textPhoneLocal: PropTypes.string,
  }).isRequired,
};

export default BankingCenterContactDetails;
