import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TranslateContext from './TranslateContext';

const TranslateProvider = ({ bilingual, children, isTouchDevice }) => {
  const [announcement, setAnnouncement] = useState(null);

  const updateAnnouncement = (event, translation) => {
    if (bilingual && event.key === '`') {
      setAnnouncement(translation);
    }
  };

  return (
    <TranslateContext.Provider value={{ announcement, bilingual, isTouchDevice, updateAnnouncement }}>
      {children}
    </TranslateContext.Provider>
  );
};

TranslateProvider.defaultProps = {
  bilingual: true,
  isTouchDevice: false,
};

TranslateProvider.propTypes = {
  bilingual: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isTouchDevice: PropTypes.bool,
};

export default TranslateProvider;
