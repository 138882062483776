import {
  CorridorAppsProvider,
  ErrorBoundary,
  IcoToolbelt,
  LoadingPage,
  PageNotFoundPage,
  Tracking,
  createIcoFetch,
  createLogger,
  useIsFeatureEnabled,
} from '@icoz-frontends/client';
import { marketConfig, marketFeatureFlagsConfig } from '@icoz-frontends/hk-market-shared';
import { MaintenanceStatusPage } from '@icoz-frontends/maintenance';
import { fflags } from '@icoz-frontends/shared';
import { Announcer, TranslateProvider } from '@icoz-frontends/translate';
import Translate from '@icoz-frontends/translate/macro';
import { Callout, Heading } from '@piggybank/core';
import dayjs from 'dayjs';
import { createBrowserHistory } from 'history';
import isTouchDevice from 'is-touch-device';
import PropTypes from 'prop-types';
import React, { Suspense, lazy } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import ScheduledMaintenance from './components/ScheduledMaintenance';
import maintenanceConfig from './config/maintenance';
import appDynamics from './constants/appDynamics';
import corridorImports from './corridorImports';
import AuthenticatedFlow from './pages/AuthenticatedFlow';
import ErrorPage from './pages/ErrorPages/ErrorPage';
import GenericError from './pages/ErrorPages/GenericError';
import MaintenancePage from './pages/MaintenancePage';
import SessionTimedOut from './pages/SessionTimedOut/SessionTimedOut';
import WelcomePage from './pages/WelcomePage';

const ErrorMessageSection = ({ message }) => (
  <Callout type="error">
    <Heading level={4} marginBottom={1}>
      Error message
    </Heading>
    <samp>{message}</samp>
  </Callout>
);

ErrorMessageSection.defaultProps = {
  message: undefined,
};

ErrorMessageSection.propTypes = {
  message: PropTypes.string,
};

const ErrorComponent = ({ message }) => {
  const isFeatureEnabled = useIsFeatureEnabled();

  return (
    <GenericError
      finalSection={isFeatureEnabled(fflags.DISPLAY_ERROR_MESSAGE) && <ErrorMessageSection message={message} />}
    />
  );
};

const ApplyComponent = () => {
  window.location.href = 'https://internationalservices.hsbc.com/overseas-account-opening/where-to-open/';
  return <LoadingPage show />;
};

ApplyComponent.defaultProps = {};

ErrorComponent.defaultProps = {
  message: undefined,
};

ErrorComponent.propTypes = {
  message: PropTypes.string,
};

const history = createBrowserHistory();
const FeatureFlipperPage = lazy(() => import('./components/FeatureFlipperPage'));
const maintenanceStartTS = dayjs(maintenanceConfig.startDateTime).valueOf();
const maintenanceEndTS = dayjs(maintenanceConfig.endDateTime).valueOf();

const App = () => (
  <CorridorAppsProvider
    componentNames={[
      'Corridor',
      'ErrorAccount',
      'ErrorAge',
      'ErrorInvalid',
      'ErrorSegment',
      'ProductBasket',
      'WelcomeProgressContent',
    ]}
    corridorImports={corridorImports}
    moduleNames={['config']}
  >
    <IcoToolbelt
      config={marketConfig}
      createIcoFetch={(config, logger) => createIcoFetch({ ...config, logger })}
      createIsFeatureEnabled={(featureFlagsEnabled, featureSet) =>
        fflags.createIsFeatureEnabled({
          config: marketFeatureFlagsConfig,
          env: featureSet,
          isCookieSourceEnabled: featureFlagsEnabled,
        })
      }
      createLogger={(splunkRoute, isProd) => createLogger(splunkRoute, isProd)}
    >
      {({ config, isFeatureEnabled, logger }) => {
        const {
          dashboardUrl,
          featureFlagsEnabled,
          logEventCodes,
          routes,
          tealiumTracking,
          trackingEnvironment,
        } = config;

        return (
          <ErrorBoundary
            errorComponent={<ErrorComponent />}
            onError={error => {
              logger(logEventCodes.BROWSER_EXCEPTION, error);
            }}
          >
            <Tracking
              appDynamicsKeys={appDynamics}
              enabled={isFeatureEnabled(fflags.USE_TRACKING)}
              environment={trackingEnvironment}
              tealiumConfig={{
                accountId: tealiumTracking.accountId,
                profileId: tealiumTracking.profileId,
              }}
            />
            <TranslateProvider bilingual={isFeatureEnabled(fflags.BILINGUAL)} isTouchDevice={isTouchDevice()}>
              <Announcer secondaryLocale="zh-hant">
                <span lang="en">
                  Information for screen reader users. This form will be read in English. If you prefer to hear the form
                  in traditional Chinese, please press the grave key on your keyboard. ````
                </span>
                <span lang="zh-hant">
                  此頁面支援屏幕助讀功能，您可選擇以英文或廣東話報讀。如您想轉換語言，請按鍵盤上的重音號鍵進行切換。
                </span>
              </Announcer>

              <Router history={history}>
                <ScheduledMaintenance endTimestamp={maintenanceEndTS} startTimestamp={maintenanceStartTS} />
                <Switch>
                  <Route exact path={routes.root}>
                    <WelcomePage />
                  </Route>
                  <Route exact path="/sg/apply" render={() => <ApplyComponent />} />
                  <Route
                    exact
                    path={`/:inboundCountry${routes.apply}`}
                    render={routeProps => <AuthenticatedFlow {...routeProps} />}
                  />
                  <Route
                    exact
                    path={routes.sessionTimeout}
                    render={routeProps => <SessionTimedOut {...routeProps} />}
                  />
                  <Route exact path={routes.error} render={routeProps => <ErrorPage {...routeProps} />} />
                  {featureFlagsEnabled && (
                    <Route
                      exact
                      path={routes.featureFlipper}
                      render={() => (
                        <Suspense fallback={<LoadingPage show />}>
                          <FeatureFlipperPage />
                        </Suspense>
                      )}
                    />
                  )}
                  <Route
                    exact
                    path={routes.maintenance}
                    render={() => (
                      <MaintenancePage endTimestamp={maintenanceEndTS} startTimestamp={maintenanceStartTS} />
                    )}
                  />
                  <Route
                    exact
                    path={routes.maintenanceStatus}
                    render={() => (
                      <MaintenanceStatusPage endTimestamp={maintenanceEndTS} startTimestamp={maintenanceStartTS} />
                    )}
                  />
                  <Route path="*">
                    <PageNotFoundPage
                      dashboardUrl={dashboardUrl}
                      textMap={{
                        button: <Translate inline>errorNotFound.cta</Translate>,
                        heading: <Translate>errorNotFound.main.heading</Translate>,
                        lead: <Translate>errorNotFound.main.paragraph</Translate>,
                        title: '404',
                      }}
                    />
                  </Route>
                </Switch>
              </Router>
            </TranslateProvider>
          </ErrorBoundary>
        );
      }}
    </IcoToolbelt>
  </CorridorAppsProvider>
);

export default App;
