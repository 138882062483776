import { Button, FormLayout, Heading, IconStatusInformationOnlight, Paragraph, Section } from '@piggybank/core';
import PropTypes from 'prop-types';
import React from 'react';
import useTealiumTracking, { trackView } from '../../hooks/tracking/useTealiumTracking';
import FullScreenModal from '../FullScreenModal/FullScreenModal';
import PageTitle from '../PageTitle/PageTitle';
import ScrollToTopWrapper from '../ScrollToTopWrapper/ScrollToTopWrapper';
import styles from './index.module.css';

const SaveErrorModal = ({ contentMap, onClick, show, textMap }) => {
  const textMapWithDefaults = {
    modalId: 'save-error',
    trackingPageTitle: 'Technical on save error',
    ...textMap,
  };

  useTealiumTracking(textMapWithDefaults.trackingPageTitle, [trackView({}, true)], { sendTracking: show });

  return (
    <FullScreenModal id={textMapWithDefaults.modalId} show={show}>
      {titleId => (
        <ScrollToTopWrapper>
          <FormLayout>
            <PageTitle>{textMapWithDefaults.pageTitle}</PageTitle>

            <Section>
              <div className={styles.icon}>
                <IconStatusInformationOnlight scaled />
              </div>

              <Heading id={titleId} level={1}>
                {contentMap.heading}
              </Heading>
              <Paragraph lead marginBottom={6}>
                {contentMap.leadParagraph}
              </Paragraph>
              <Paragraph marginBottom={6}>{contentMap.paragraph1}</Paragraph>

              <Button onClick={onClick} type="button">
                {contentMap.okContinue}
              </Button>
            </Section>
          </FormLayout>
        </ScrollToTopWrapper>
      )}
    </FullScreenModal>
  );
};

SaveErrorModal.defaultProps = {
  textMap: {},
};

SaveErrorModal.propTypes = {
  contentMap: PropTypes.shape({
    heading: PropTypes.element.isRequired,
    leadParagraph: PropTypes.element.isRequired,
    okContinue: PropTypes.element.isRequired,
    paragraph1: PropTypes.element.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  textMap: PropTypes.shape({
    modalId: PropTypes.string,
    pageTitle: PropTypes.string.isRequired,
    trackingPageTitle: PropTypes.string,
  }),
};

export default SaveErrorModal;
