import { Fieldset, Hint, Legend, YesNoRadio } from '@piggybank/form';
import PropTypes from 'prop-types';
import React from 'react';

const DevMode = ({ setHaveValuesChanged }) => (
  <Fieldset
    name="devMode"
    onChange={(next, rest) => {
      setHaveValuesChanged(true);
      next(rest);
    }}
    required
  >
    <Legend>Select the development mode</Legend>
    <Hint>Switch to PROD mode to mimic how the app will behave in production.</Hint>
    <YesNoRadio
      fullWidth
      textMap={{
        no: 'DEV',
        yes: 'PROD',
      }}
      values={{ no: 'DEV', yes: 'PROD' }}
    />
  </Fieldset>
);

DevMode.propTypes = {
  setHaveValuesChanged: PropTypes.func.isRequired,
};

export default DevMode;
