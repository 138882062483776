import {
  FormLayout,
  Heading,
  Page,
  Panel,
  Paragraph,
  PlaybackKey,
  PlaybackList,
  PlaybackValue,
  Section,
} from '@piggybank/core';
import React, { useEffect, useRef, useState } from 'react';

interface MaintenanceStatusPageProps {
  endTimestamp: number;
  startTimestamp: number;
}

export default (props: MaintenanceStatusPageProps): React.ReactElement => {
  const { endTimestamp, startTimestamp } = props;
  const start = new Date(startTimestamp);
  const end = new Date(endTimestamp);
  const interval = useRef<number>();

  const getStatus = () => {
    const currentTimestamp = Date.now();
    let status = 'Ongoing';

    if (startTimestamp > currentTimestamp) {
      status = 'Upcoming';
    } else if (currentTimestamp > endTimestamp) {
      status = 'Finished';
    }

    return status;
  };

  const [status, setStatus] = useState(getStatus());

  useEffect(() => {
    interval.current = window.setInterval(() => setStatus(getStatus()), 1000);

    return () => {
      window.clearInterval(interval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      <FormLayout>
        <Section>
          <Heading level={1} marginBottom={5}>
            Scheduled maintenance
          </Heading>
          <Paragraph lead>Status: {status}</Paragraph>

          <Panel type="silver-border">
            <Heading level={3}>Start</Heading>
            <PlaybackList>
              <PlaybackKey>UTC</PlaybackKey>
              <PlaybackValue>{start.toISOString()}</PlaybackValue>
              <PlaybackKey>Local time</PlaybackKey>
              <PlaybackValue>{start.toString()}</PlaybackValue>
              <PlaybackKey>Timestamp</PlaybackKey>
              <PlaybackValue>{startTimestamp}</PlaybackValue>
            </PlaybackList>
          </Panel>
          <Panel type="silver-border">
            <Heading level={3}>End</Heading>
            <PlaybackList>
              <PlaybackKey>UTC</PlaybackKey>
              <PlaybackValue>{end.toISOString()}</PlaybackValue>
              <PlaybackKey>Local time</PlaybackKey>
              <PlaybackValue>{end.toString()}</PlaybackValue>
              <PlaybackKey>Timestamp</PlaybackKey>
              <PlaybackValue>{endTimestamp}</PlaybackValue>
            </PlaybackList>
          </Panel>
        </Section>
      </FormLayout>
    </Page>
  );
};
