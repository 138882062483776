import {
  Button,
  FormLayout,
  Heading,
  HorizontalRule,
  IconStatusInformationOnlight,
  Paragraph,
  Section,
  getMarginBottomClass,
} from '@piggybank/core';
import PropTypes from 'prop-types';
import React from 'react';
import useConfig from '../../hooks/useConfig';
import useLoggerEffect from '../../hooks/useLoggerEffect';
import PageTitle from '../PageTitle/PageTitle';
import ScrollToTopWrapper from '../ScrollToTopWrapper/ScrollToTopWrapper';
import styles from './PreExistingCustomerData.module.css';

const PreExistingCustomerDataGeneralEligibilityError = ({ CustomerCareContent, onCTAKeyDown, textMap }) => {
  const { dashboardUrl, logEventCodes } = useConfig();
  useLoggerEffect(logEventCodes.ELIGIBILITY_ERROR);

  return (
    <ScrollToTopWrapper>
      <PageTitle>{textMap.title}</PageTitle>

      <FormLayout>
        <Section>
          <div className={`${styles.icon} ${getMarginBottomClass(3)}`}>
            <IconStatusInformationOnlight scaled />
          </div>
          <Heading level={1}>{textMap.heading}</Heading>
          <Paragraph lead>{textMap.leadParagraph}</Paragraph>
          <HorizontalRule />
        </Section>

        <Section>
          <Heading accentBar level={2}>
            {textMap.helpHeading}
          </Heading>
          <Paragraph>{textMap.helpParagraph}</Paragraph>

          <div className={getMarginBottomClass(6)}>
            <CustomerCareContent />
          </div>

          <Button elementType="a" href={dashboardUrl} onKeyDown={onCTAKeyDown}>
            {textMap.cta}
          </Button>
        </Section>
      </FormLayout>
    </ScrollToTopWrapper>
  );
};

PreExistingCustomerDataGeneralEligibilityError.propTypes = {
  CustomerCareContent: PropTypes.func.isRequired,
  onCTAKeyDown: PropTypes.func,
  textMap: PropTypes.shape({
    cta: PropTypes.node,
    heading: PropTypes.node,
    helpHeading: PropTypes.node,
    helpParagraph: PropTypes.node,
    leadParagraph: PropTypes.node,
    title: PropTypes.string,
  }),
};

PreExistingCustomerDataGeneralEligibilityError.defaultProps = {
  onCTAKeyDown: undefined,
  textMap: {
    cta: <></>,
    heading: <></>,
    helpHeading: <></>,
    helpParagraph: <></>,
    leadParagraph: <></>,
    title: 'Application error',
  },
};

export default PreExistingCustomerDataGeneralEligibilityError;
