import { useState } from 'react';

/* eslint-disable no-shadow */
/* Please fix linting errors next time file is touched */

export default fetchFn => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const returnFn = async (...args) => {
    setLoading(true);

    try {
      const data = await fetchFn(...args);
      setData(data);
    } catch (error) {
      const { data, message, statusCode } = error;

      setError({
        ...(statusCode ? { statusCode } : {}),
        ...(data ? { data } : {}),
        ...(message ? { message } : {}),
      });
    } finally {
      setLoading(false);
    }
  };

  return [returnFn, loading, error, data];
};
