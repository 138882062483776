export default (errors, textMap) =>
  errors
    .map(item => {
      const { code, jsonPath } = item;
      const fieldNameParts = jsonPath.replace('$.data.', '').split('.');
      const fieldName = fieldNameParts.join('.');
      const key = `${code}-${fieldName}`;

      if (!textMap[code]) {
        return { key, message: fieldName };
      }

      const message =
        textMap[code][fieldName] || textMap[code][fieldNameParts[0]] || textMap[code][fieldNameParts[1]] || fieldName;

      return {
        key,
        message,
      };
    })
    .filter((value, index, self) => self.findIndex(v => v.message.toString() === value.message.toString()) === index);
