import logEventCodes from './logEventCodes';
import routes from './routes';

const config = {
  applicationSchemaVersion: '1.0.0',
  channelId: 'WEB',
  cookieMaxAge: 1000 * 60 * 5, // 5 mins
  cookieName: 'session',
  fallbackBookingUrl: `https://www.appointmentbooking.hsbc.co.uk/group-appointmentbooking-ui-angular-web/#/cusaptboo?user=Prospect`,
  featureFlagsCookieName: 'featureFlagOverrides',
  icoApiApplicationStateIdPath: `/ico-application-state-id?source={outboundCountryCode}&destination={inboundCountryCode}`,
  icoApiCustomerDetailsPath: `/ico-customer?source={outboundCountryCode}&format={inboundCountryCode}&shouldMask={shouldMask}`,
  icoApiStatusPath: '/status',
  icoApiSubmissionContinuationPath: '/ico-application-continuation',
  icoApiSubmissionPath: '/ico-application',
  icoNodeApplicationStatePath: '/api/application-state',
  icoNodeCustomerDetailsPath: '/api/customer-details',
  icoNodeDownload: '/download/{filename}',
  icoNodeSaveApplicationPath: '/api/save-application',
  icoNodeStatusPath: '/api/status',
  icoNodeSubmitApplicationContinuationPath: '/api/submit-application-continuation',
  icoNodeSubmitApplicationPath: '/api/submit-application',
  shouldMask: false,
  targetCAMLevel: '40',
  tealiumTracking: {
    accountId: 'hsbc',
    businessLine: 'rbwm',
    customerGroup: 'general',
    defaultPageType: 'application',
    events: {
      customerDataAccountError: {
        restData: {
          error_type: 'application',
          event_action: 'application',
          event_category: 'error',
          event_content: 'invalid account',
        },
        title: 'Invalid Account',
      },
      customerDataAgeError: {
        restData: {
          error_type: 'application',
          event_action: 'application',
          event_category: 'error',
          event_content: 'invalid age-under 18',
        },
        title: 'Invalid Age',
      },
      customerDataInvalidError: {
        restData: {
          error_type: 'application',
          event_action: 'application',
          event_category: 'error',
          event_content: 'review profile information',
        },
        title: 'Review Profile Information',
      },
      customerDataSegmentError: {
        restData: {
          error_type: 'application',
          event_action: 'application',
          event_category: 'error',
          event_content: 'invalid customer',
        },
        title: 'Invalid Customer Type',
      },
      errorPage: {
        restData: {
          error_type: 'system',
        },
        title: 'Technical error',
      },
      retryUploadError: {
        restData: {
          error_type: 'system',
        },
        title: 'Unstable Connection',
      },
      sessionTimedOutPage: {
        restData: {
          error_type: 'application',
          event_action: 'application',
        },
        title: 'Timeout',
      },
      sessionTimeoutPopup: {
        restData: {
          error_type: 'application',
          event_action: 'application',
        },
        title: 'Need More Time Popup',
      },
      uploadDocuments: {
        restData: {
          error_type: 'upload',
          event_action: 'upload',
        },
        title: 'Upload documents',
      },
      uploadError: {
        restData: {
          error_type: 'upload',
          event_action: 'upload',
          event_content: 'upload error-continue to complete and submit application',
        },
        title: 'Upload Error',
      },
      uploadProcessing: {
        restData: {
          funnel_step: 6,
          page_name: 'pws:international customer onboarding:upload documents:processing',
        },
        title: 'Upload documents - processing',
      },
    },
    firstFormPageIndex: 1,
    funnelCorridor: '',
    funnelName: 'international customer onboarding',
    language: 'en',
    pageCustomerGroup: 'general',
    productCategory: '',
    productId: '',
    productSubCategory: '',
    profileId: 'global-rbwm-ico',
    siteCountry: '',
    siteRegion: '',
    siteSection: 'pws',
  },
};

export default {
  ...config,
  logEventCodes,
  routes,
};
