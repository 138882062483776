/**
 * Makes an object with convenience functions for imperative field manipulation
 * @param {func} setFieldValue setFieldValues function from Formik
 * @param {Object} initialValues initial form values (used for resetting fields)
 */
export default (setFieldValue, initialValues) => ({
  combineCallbacks: (callbacks = []) => (next, rest) => {
    const combined = callbacks.reduceRight((acc, current) => r => current(acc, r), next);

    return combined(rest);
  },

  resetFieldsIf: (value, fieldNames) => (next, rest) => {
    const saneFieldNames = Array.isArray(fieldNames) ? fieldNames : [fieldNames];

    if (rest.value === value) {
      saneFieldNames.forEach(fieldName => setFieldValue(fieldName, initialValues[fieldName]));
    }

    return next(rest);
  },

  resetFieldsUnless: (value, fieldNames) => (next, rest) => {
    const saneFieldNames = Array.isArray(fieldNames) ? fieldNames : [fieldNames];

    if (rest.value !== value) {
      saneFieldNames.forEach(fieldName => setFieldValue(fieldName, initialValues[fieldName]));
    }

    return next(rest);
  },
});
