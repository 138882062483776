const root = '/';

export default {
  apply: `${root}apply`,
  auth: `${root}authenticate`,
  error: `${root}error`,
  featureFlipper: `${root}_features`,
  maintenance: `${root}maintenance`,
  maintenanceStatus: `${root}maintenance/status`,
  root,
  sessionTimeout: `${root}timeout`,
  splunk: `${root}log`,
  // Must be non-prod only!
  static: `${root}static`,
  status: `${root}status`,
};
