import { Button, Expander, Heading, IconCalendar, Paragraph, getMarginBottomClass } from '@piggybank/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import useConfig from '../../hooks/useConfig';

/* eslint-disable react/require-default-props */
/* Please fix linting errors next time file is touched */

const propTypes = {
  children: PropTypes.node.isRequired,
  showButton: PropTypes.bool,
  title: PropTypes.string,
};

const UpdatingLockedDataExpander = ({ children, showButton, title = 'How to update locked information' }) => {
  const { fallbackBookingUrl } = useConfig();
  return (
    <Expander title={title}>
      <Paragraph>{children}</Paragraph>

      <Heading level={4}>Personal Banking customers</Heading>

      <Paragraph hint>
        Lines are open from 8am to 10pm every day. For Advance and Premier customers, lines are open 24/7.
      </Paragraph>

      <Paragraph marginBottom={0}>03457 707 070</Paragraph>

      <Paragraph small>Textphone (for customers with hearing and speech impairments) 03457 125 563</Paragraph>

      {showButton && (
        <div className={getMarginBottomClass(3)}>
          <Button
            elementType="a"
            external
            href={fallbackBookingUrl}
            iconSlotLeft={<IconCalendar inline />}
            secondary
            textMap={{ newWindow: 'Opens in a new window' }}
          >
            Book an appointment
          </Button>
        </div>
      )}
      <Paragraph marginBottom={0}>
        <strong>Make sure your details are updated before you submit your application.</strong>
      </Paragraph>
    </Expander>
  );
};

UpdatingLockedDataExpander.propTypes = propTypes;

export default UpdatingLockedDataExpander;
