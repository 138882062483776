import { fflags, getEnvVar } from '@icoz-frontends/shared';
import { cloneDeep, isEqual, startCase } from 'lodash';
import { useEffect } from 'react';
import useConfig from './useConfig';
import useIsFeatureEnabled from './useIsFeatureEnabled';
import useLogger from './useLogger';
import usePrevious from './usePrevious';

const previousApplications = {};
let previousChangedFieldNames = '';

export default (values, { reset = false } = {}) => {
  const { corridor, logEventCodes } = useConfig();
  const logger = useLogger();
  const isFeatureEnbled = useIsFeatureEnabled();
  const update = usePrevious(true);

  useEffect(() => {
    if (getEnvVar('BABEL_ENV') === 'test') {
      return;
    }

    const inboundCountry = corridor.inboundCountry.abbrev.toUpperCase();

    if (!update && reset) {
      delete previousApplications[inboundCountry];
    }

    const previousApplication = previousApplications[inboundCountry];

    if ((!previousApplication && !values) || isEqual(values, previousApplication)) {
      return;
    }

    let changed = values;
    let action = 'created';

    if (previousApplication) {
      action = 'changed';

      changed = Object.keys(values).reduce((obj, key) => {
        if (!isEqual(values[key], previousApplication[key])) {
          obj[key] = values[key];
        }

        return obj;
      }, {});
    }

    const changedFieldNames = Object.keys(changed).join(', ');

    if (
      isFeatureEnbled(fflags.LOG_FORM_INTERACTIONS) &&
      update &&
      changedFieldNames.length &&
      changedFieldNames !== previousChangedFieldNames
    ) {
      logger(logEventCodes.FORM_INTERACTION, { fields: changedFieldNames }, 'debug');
      previousChangedFieldNames = changedFieldNames;
    }

    if (getEnvVar('REACT_APP_ENV') === 'dev') {
      /* eslint-disable no-console */
      console.log(`Application state ${action}`);
      console.log(`${startCase(action)} fields`, changed);
      /* eslint-enable no-console */
    }

    previousApplications[inboundCountry] = cloneDeep(values);
  });
};
