import parser from 'html-react-parser';
import { castArray } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from './useTranslate';

const Translations = ({ children, inline, isIconLabel, isLabel, locale, styles, translationKey, translations }) => {
  const { bilingual = true, isTouchDevice = false } = useTranslate() || {};

  const getValidChildren = () => {
    const castChildren = React.Children.toArray(children);

    if (!bilingual) {
      return [castChildren[0]];
    }

    return locale ? castChildren.filter(child => child.key === locale) : castChildren;
  };

  const getValidTranslations = () => {
    const castTranslations = castArray(translations);

    if (!bilingual) {
      return [castTranslations[0]];
    }

    return locale ? castTranslations.filter(trans => trans[0] === locale) : castTranslations;
  };

  const renderChildren = () =>
    getValidChildren().map((child, index) =>
      React.cloneElement(child, {
        'aria-hidden': index === 1 && isLabel && !isTouchDevice ? true : undefined,
        style: index === 1 && !inline ? styles.block : undefined,
      }),
    );

  const renderTranslations = () =>
    getValidTranslations().map((trans, index) => (
      <span
        aria-hidden={index === 1 && isLabel && !isTouchDevice ? true : undefined}
        key={trans[0]}
        lang={trans[0]}
        style={index === 1 && !inline ? styles.block : undefined}
      >
        {parser(trans[1])}
      </span>
    ));

  const content = children ? renderChildren() : renderTranslations();

  if (content.length === 1) {
    return React.cloneElement(content[0], {
      'data-translation-key': translationKey,
      'data-translation-root': true,
    });
  }

  return (
    <span
      data-translation-key={translationKey}
      data-translation-root
      style={isIconLabel ? styles.iconLabel : undefined}
    >
      {content[0]}
      {inline && ` `}
      {content[1]}
    </span>
  );
};

Translations.defaultProps = {
  children: undefined,
  inline: false,
  isIconLabel: false,
  isLabel: false,
  locale: undefined,
  styles: {
    block: {
      display: 'block',
    },
    iconLabel: {
      display: 'inline-grid',
    },
  },
  translationKey: undefined,
  translations: [],
};

Translations.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  inline: PropTypes.bool,
  isIconLabel: PropTypes.bool,
  isLabel: PropTypes.bool,
  locale: PropTypes.string,
  styles: PropTypes.shape({
    block: PropTypes.objectOf(PropTypes.string),
    iconLabel: PropTypes.objectOf(PropTypes.string),
  }),
  translationKey: PropTypes.string,
  translations: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

export default Translations;
