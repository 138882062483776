import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  children: PropTypes.node.isRequired,
  errorComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  onError: PropTypes.func,
};

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    return { hasError: true, message: error.message };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    const { onError } = this.props;

    if (onError) {
      onError({
        exception_class: error.name,
        exception_component_trace: errorInfo.componentStack,
        exception_message: error.message,
        exception_stack_trace: error.stack,
      });
    }
  }

  render() {
    const { hasError, message } = this.state;
    const { children, errorComponent } = this.props;

    if (hasError) {
      return typeof errorComponent === 'function' ? errorComponent({ message }) : errorComponent;
    }

    return children;
  }
}

ErrorBoundary.propTypes = propTypes;

ErrorBoundary.defaultProps = {
  onError: null,
};

export default ErrorBoundary;
