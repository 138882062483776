import { Loading, useCorridorComponents } from '@icoz-frontends/client';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import GenericError from './GenericError';

const ErrorTypePage = ({ corridorConfig, data, inboundCountry, type }) => {
  const { ErrorAccount, ErrorAge, ErrorInvalid, ErrorSegment } = useCorridorComponents(inboundCountry, {
    importKey: 'main',
  });

  let ErrorType;

  switch (type) {
    case 'account':
      ErrorType = ErrorAccount;
      break;

    case 'age':
      ErrorType = ErrorAge;
      break;

    case 'invalid':
      ErrorType = ErrorInvalid;
      break;

    case 'segment':
      ErrorType = ErrorSegment;
      break;

    default:
      ErrorType = GenericError;
  }

  return (
    <Suspense fallback={<Loading />}>
      <ErrorType corridorConfig={corridorConfig} errors={data} inboundCountry={inboundCountry} />
    </Suspense>
  );
};

ErrorTypePage.defaultProps = {
  corridorConfig: {},
  data: undefined,
};

ErrorTypePage.propTypes = {
  corridorConfig: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      detail: PropTypes.string,
      jsonPath: PropTypes.string,
      source: PropTypes.string,
    }),
  ),
  inboundCountry: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ErrorTypePage;
