import Cookie from 'js-cookie';

const DEV_SETTINGS = 'DEV_SETTINGS';

export const removeDevSettings = () => {
  Cookie.remove(DEV_SETTINGS);
  sessionStorage.removeItem(DEV_SETTINGS);
};

export const retrieveDevSettings = ({ parse = true, type } = {}) => {
  const retrieved = sessionStorage.getItem(DEV_SETTINGS);

  if (!retrieved) {
    return undefined;
  }

  if (!parse) {
    return retrieved;
  }

  const [settings, config] = JSON.parse(retrieved);

  if (type === 'settings') {
    return settings;
  }

  if (type === 'config') {
    return config;
  }

  return [settings, config];
};

export const storeDevSettings = (settings, config) => {
  const settingsString = JSON.stringify([settings, config]);
  Cookie.set(DEV_SETTINGS, settingsString);
  sessionStorage.setItem(DEV_SETTINGS, settingsString);
};
