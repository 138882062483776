import { countries } from '@icoz-frontends/data';

const getCountryLabel = countryCode => countries?.[countryCode.toUpperCase()]?.label;

export default (address = {}, formatters = {}) => {
  if (!Object.keys(address).length) {
    return 'Invalid address';
  }

  const { country, startDate, ...addressLines } = address;
  const formatter = formatters[country.toLowerCase()];

  if (formatter) {
    return formatter(address);
  }

  return Object.keys(addressLines)
    .map(key => addressLines[key])
    .concat(getCountryLabel(country))
    .filter(Boolean)
    .join(', ');
};
