import { PlaybackKey, PlaybackValue } from '@piggybank/core';
import PropTypes from 'prop-types';
import React from 'react';
import { formatTelephoneNumber } from '../../../dataFormatters';

const Review = ({ fallback, heading, value }) => (
  <>
    <PlaybackKey>{heading}</PlaybackKey>
    <PlaybackValue>{formatTelephoneNumber(value) || fallback}</PlaybackValue>
  </>
);

Review.defaultProps = {
  fallback: 'Not provided',
  value: undefined,
};

Review.propTypes = {
  fallback: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  heading: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  value: PropTypes.string,
};

export default Review;
