import performDevModeSideEffects from './DevMode/performSideEffects';

export default (initialValues, values) => {
  Object.keys(values).forEach(key => {
    switch (key) {
      case 'devMode':
        performDevModeSideEffects(values, initialValues[key], values[key]);
        break;
      // no default
    }
  });
};
