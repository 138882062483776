const isPrefixedWithThe = abbrev => ['gb', 'ph', 'us'].includes(abbrev);

export default (abbrev, markets) => {
  const market = markets[abbrev.toUpperCase()];

  if (!market) {
    return 'Null Island';
  }

  const { name } = market;
  return isPrefixedWithThe(abbrev) ? `the ${name}` : name;
};
