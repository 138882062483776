import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';
import useConfig from '../../hooks/useConfig';

const NON_GENERIC_ERROR_CODES = {
  CUSTOMER_TOO_YOUNG: 'CUSTOMER_TOO_YOUNG',
  EXPECTED_FIELD_MISSING: 'EXPECTED_FIELD_MISSING',
  INCOMPLETE_ADDRESS_HISTORY: 'INCOMPLETE_ADDRESS_HISTORY',
  INVALID_ADDRESS: 'INVALID_ADDRESS', // TODO refactor be & fe to generic INVALID_PATTERN
  INVALID_EMAIL: 'INVALID_EMAIL', // TODO refactor be & fe to generic INVALID_PATTERN
  INVALID_EMAIL_CHARS: 'INVALID_EMAIL_CHARS', // TODO refactor be & fe to generic INVALID_CHARS
  INVALID_EMAIL_LONG: 'INVALID_EMAIL_LONG', // TODO refactor be & fe to be generic INVALID_LONG
  INVALID_ENUM_VALUE: 'INVALID_ENUM_VALUE',
  INVALID_PATTERN: 'INVALID_PATTERN',
  INVALID_PHONE_LONG: 'INVALID_PHONE_LONG', // TODO refactor be & fe to generic INVALID_LONG
  INVALID_PHONE_SHORT: 'INVALID_PHONE_SHORT', // TODO refactor be & fe to generic INVALID_SHORT
  MISSING_VALID_ACCOUNT: 'MISSING_VALID_ACCOUNT',
  PO_BOX_ADDRESS_NOT_SUPPORTED: 'PO_BOX_ADDRESS_NOT_SUPPORTED',
  UNSUPPORTED_CUSTOMER_SEGMENT: 'UNSUPPORTED_CUSTOMER_SEGMENT',
};

const ErrorRouter = ({ errors, inboundCountry }) => {
  const corridorConfig = useConfig();
  let errorType;

  switch (true) {
    case !errors || errors.every(({ code }) => !NON_GENERIC_ERROR_CODES[code]):
      errorType = 'generic';
      break;

    case errors && errors.some(({ code }) => code === NON_GENERIC_ERROR_CODES.MISSING_VALID_ACCOUNT):
      errorType = 'account';
      break;

    case errors && errors.some(({ code }) => code === NON_GENERIC_ERROR_CODES.CUSTOMER_TOO_YOUNG):
      errorType = 'age';
      break;

    case errors && errors.some(({ code }) => code === NON_GENERIC_ERROR_CODES.UNSUPPORTED_CUSTOMER_SEGMENT):
      errorType = 'segment';
      break;

    default:
      errorType = 'invalid';
  }

  return (
    <Redirect
      to={{
        pathname: corridorConfig.routes.error,
        state: { corridorConfig, errorData: errors, errorType, inboundCountry },
      }}
    />
  );
};

ErrorRouter.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      detail: PropTypes.string,
      jsonPath: PropTypes.string,
      source: PropTypes.string,
    }),
  ),
  inboundCountry: PropTypes.string.isRequired,
};

ErrorRouter.defaultProps = {
  errors: [],
};

export default ErrorRouter;
