import useTealiumTracking, { trackView } from './useTealiumTracking';

export default ({ applicationReference, applicationStatus }) => {
  let decision;
  let event = 'completed';
  let title = 'Confirmation';

  if (applicationStatus) {
    decision = 'completed';

    if (applicationStatus === 'REFER') {
      decision = 'referred';
      event = 'referred';
      title = 'Referred';
    }
  }

  useTealiumTracking(title, [
    trackView({
      application_decision: decision,
      application_event: event,
      application_id: applicationReference,
      funnel_step: 10,
    }),
  ]);
};
