const caTerritories = require('./caTerritories.json');
const countries = require('./countries.json');
const customerSegments = require('./customerSegments.json');
const markets = require('./markets.json');
const usTerritories = require('./usTerritories.json');

module.exports = {
  caTerritories,
  countries,
  customerSegments,
  markets,
  usTerritories,
};
