import { Heading, PlaybackKey, PlaybackList, PlaybackValue, Section } from '@piggybank/core';
import PropTypes from 'prop-types';
import React from 'react';
import { formatAddress } from '../../../dataFormatters';

const Review = ({ contentMap, customerData, values }) => (
  <Section>
    <Heading component="h2" level={3}>
      {contentMap.heading}
    </Heading>

    <PlaybackList type="review">
      <PlaybackKey>{contentMap.label}</PlaybackKey>
      <PlaybackValue>
        {values.isCorrespondenceAddressSameAsResidential
          ? customerData.currentResidentialAddress?.value
          : formatAddress(values.correspondenceAddress)}
      </PlaybackValue>
    </PlaybackList>
  </Section>
);

Review.propTypes = {
  contentMap: PropTypes.shape({
    heading: PropTypes.element.isRequired,
    label: PropTypes.element.isRequired,
  }).isRequired,
  customerData: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Review;
