import { IconSecuritySecure, VisuallyHidden, getMarginBottomClass, marginBottomLevels } from '@piggybank/core';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './LockedDataList.module.css';

/* eslint-disable react/require-default-props */
/* Please fix linting errors next time file is touched */

const propTypes = {
  children: PropTypes.node,
  marginBottom: PropTypes.oneOf(marginBottomLevels),
};

const LockedDataList = ({ children, marginBottom }) => (
  <dl className={getMarginBottomClass(marginBottom)}>{children}</dl>
);

const LockedDataKey = ({ children, hiddenText }) => (
  <dt className={styles.key}>
    <span className={styles.icon}>
      <IconSecuritySecure inline />
    </span>
    {children}
    <VisuallyHidden>{hiddenText}</VisuallyHidden>
  </dt>
);

const LockedDataValue = ({ children, marginBottom }) => (
  <dd className={getMarginBottomClass(marginBottom)}>{children}</dd>
);

LockedDataList.defaultProps = {
  marginBottom: 3,
};

LockedDataKey.defaultProps = {
  hiddenText: 'Field is locked for editing',
};

LockedDataValue.defaultProps = {
  marginBottom: 4,
};

LockedDataList.propTypes = propTypes;

LockedDataKey.propTypes = {
  ...propTypes,
  hiddenText: PropTypes.string,
};

LockedDataValue.propTypes = propTypes;

export { LockedDataList, LockedDataKey, LockedDataValue };
