import { IconStatusConfirmationOnlight } from '@piggybank/core';
import React from 'react';
import styles from './index.module.css';

const ApplicationCompleteTick = () => (
  <div className={styles.confirmation}>
    <IconStatusConfirmationOnlight scaled />
  </div>
);

export default ApplicationCompleteTick;
