import isEmpty from 'lodash/fp/isEmpty';
import traverse from 'traverse';

/* eslint-disable */

function getNestedValues(input) {
  if (typeof input === 'object') {
    if (isEmpty(input)) {
      return [];
    } else {
      return traverse(input).reduce(function(acc, curr) {
        const path = [...this.path];

        if (['value', 'raw'].includes(path[path.length - 1])) {
          path.pop();
        }

        return this.isLeaf && curr
          ? [...acc, { path: path.join('.'), value: curr }]
          : acc;
      }, []);
    }
  }

  return input;
}

export default getNestedValues;
