import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@icoz-frontends/client/polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import '@piggybank/core/lib/styles.css';
import '@piggybank/form/lib/styles.css';
import '@icoz-frontends/client/dist/esm/index.css';
import '@icoz-frontends/dev-settings/dist/esm/index.css';

ReactDOM.render(<App />, document.getElementById('root'));
