import { Machine, assign } from 'xstate';
import { IDLE, SAVED, SAVING, UNSAVED } from './constants';

export default Machine(
  {
    context: {
      errors: 0,
    },
    id: 'saveApplication',
    initial: IDLE,
    states: {
      idle: {
        on: {
          SAVE: {
            target: SAVING,
          },
        },
      },
      saved: {
        on: {
          RESET: {
            target: IDLE,
          },
        },
      },
      saving: {
        on: {
          FAILURE: {
            actions: ['setError'],
            target: UNSAVED,
          },
          SUCCESS: {
            target: SAVED,
          },
        },
      },
      unsaved: {
        on: {
          RESET: {
            target: IDLE,
          },
        },
      },
    },
  },
  {
    actions: {
      setError: assign({
        errors: context => context.errors + 1,
      }),
    },
  },
);
