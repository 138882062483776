import { buildHeadersObject, fetchUtils, round } from '@icoz-frontends/shared';
import fromPairs from 'lodash/fromPairs';
import { v4 as uuidv4 } from 'uuid';
import IcoFetch from './IcoFetch';
import getDocUploadRequestData from './helpers/getDocUploadRequestData';

const {
  conditionallyParseResponseBody,
  defaultHeadersBlacklist,
  defaultResponseBodyWhitelist,
  filterHeaders,
  formatResponseHeaders,
} = fetchUtils;

const buildHeaders = (headers = {}) => ({
  'Content-Type': 'application/json',
  ...buildHeadersObject(headers),
});

export default ({
  docUpload,
  icoNodeApplicationStatePath,
  icoNodeSaveApplicationPath,
  icoNodeStatusPath,
  icoNodeSubmitApplicationContinuationPath,
  icoNodeSubmitApplicationPath,
  isProd,
  logEventCodes,
  logger,
}) => {
  const icoFetch = new IcoFetch({ logEventCodes, logger });

  icoFetch.addMethod('getApplicationState', function getApplicationState(metadata = {}) {
    return async () => {
      const { data, error, ok } = await this.get(icoNodeApplicationStatePath, {
        headers: buildHeaders(metadata.headers),
      });

      if (!ok) {
        throw error;
      }

      return data;
    };
  });

  icoFetch.addMethod('getServiceStatus', function getServiceStatus(metadata = {}) {
    return async () => {
      const { data, error, ok } = await this.get(icoNodeStatusPath, {
        headers: buildHeaders(metadata.headers),
      });

      if (!ok) {
        throw error;
      }

      return data;
    };
  });

  icoFetch.addMethod('saveApplication', function saveApplication(metadata = {}) {
    return async saveData => {
      const { data, error, ok } = await this.post(icoNodeSaveApplicationPath, {
        body: JSON.stringify({ data: saveData }),
        headers: buildHeaders(metadata.headers),
      });

      if (!ok) {
        throw error;
      }

      return data;
    };
  });

  icoFetch.addMethod('submitApplication', function submitApplication(metadata = {}) {
    return async submitData => {
      const { inboundCountry, outboundCountry } = submitData;

      const { data, error, ok } = await this.post(icoNodeSubmitApplicationPath, {
        body: JSON.stringify({ data: submitData }),
        headers: buildHeaders(metadata.headers),
      });

      if (!ok) {
        throw error;
      }

      if (data?.applicationStatus !== 'IN_PROGRESS') {
        return data;
      }

      return this._submitApplicationContinuation({ ...data, inboundCountry, outboundCountry }, metadata);
    };
  });

  icoFetch.addMethod('_submitApplicationContinuation', async function _submitApplicationContinuation(
    submitData,
    metadata,
  ) {
    const { applicationProcessingId, inboundCountry, outboundCountry } = submitData;

    const body = {
      data: {
        applicationProcessingId,
        inboundCountry,
        outboundCountry,
      },
    };

    const { data, error, ok } = await this.post(icoNodeSubmitApplicationContinuationPath, {
      body: JSON.stringify(body),
      headers: buildHeaders(metadata.headers),
    });

    if (!ok) {
      throw error;
    }

    if (data?.applicationStatus !== 'IN_PROGRESS') {
      return data;
    }

    return this._submitApplicationContinuation({ ...data, inboundCountry, outboundCountry }, metadata);
  });

  icoFetch.addMethod('uploadFile', async function uploadFile(file, userData) {
    const formData = new FormData();
    const { clientId, corridorConfig, endpoint, secretId } = docUpload;

    formData.append('requestJson', JSON.stringify(getDocUploadRequestData(file, userData, corridorConfig)));
    formData.append('file', file, file.name);

    const headers =
      clientId && secretId
        ? {
            client_id: clientId,
            client_secret: secretId,
          }
        : {};

    const route = 'docUpload';
    const uuid = uuidv4();

    this.log('HTTP_REQUEST', {
      request_headers: JSON.stringify(filterHeaders(headers, defaultHeadersBlacklist, isProd)),
      request_host: endpoint,
      request_method: 'POST',
      request_route: route,
      request_uuid: uuid,
      ...(!isProd && formData
        ? {
            request_body: JSON.stringify(fromPairs(formData)),
          }
        : {}),
    });

    this.log('DOC_API_UPLOAD', {
      request_host: endpoint,
      request_uuid: uuid,
    });

    const startTime = performance.now();

    const res = await this.post(endpoint, {
      body: formData,
      headers,
    });

    const endTime = performance.now();

    this.log('HTTP_RESPONSE', {
      request_host: endpoint,
      request_response_body: await conditionallyParseResponseBody(res, defaultResponseBodyWhitelist, isProd),
      request_response_error: !res.ok,
      request_response_headers: JSON.stringify(
        filterHeaders(formatResponseHeaders(res.headers), defaultHeadersBlacklist, isProd),
      ),
      request_response_statuscode: res.status,
      request_response_time: round(endTime - startTime),
      request_response_timeout: false,
      request_route: route,
      request_uuid: uuid,
    });

    if (!res.ok) {
      this.log('DOC_API_UPLOAD_ERROR', {
        request_host: endpoint,
        request_route: route,
        request_uuid: uuid,
        statusCode: res.status,
      });

      throw res.error;
    }

    return res.data;
  });

  return icoFetch;
};
