import { markets } from '@icoz-frontends/data';
import { conf, getEnvVar } from '@icoz-frontends/shared';

export default conf.mergeConfigs(conf.globalConfig, {
  channelGroupMember: getEnvVar('REACT_APP_GROUP_MEMBER_CODE'),
  corridor: {
    availableInboundCountries: [markets.SG, markets.GB],
    outboundCountry: markets.HK,
  },
  dashboardUrl: getEnvVar('REACT_APP_PIB_DASHBOARD_URL'),
  docUpload: {
    clientId: getEnvVar('REACT_APP_DOC_UPLOAD_CLIENT_ID'),
    corridorConfig: {
      appId: 'ICOA',
      ctryCde: 'HK',
      groupMbrCde: 'HBAP',
      objStoreName: 'FS_AMH',
    },
    endpoint: getEnvVar('REACT_APP_DOC_UPLOAD_ENDPOINT'),
    secretId: getEnvVar('REACT_APP_DOC_UPLOAD_CLIENT_SECRET'),
  },
  env: getEnvVar('REACT_APP_ENV'),
  expressHost: getEnvVar('REACT_APP_EXPRESS_HOST') || '',
  fakeCin: getEnvVar('REACT_APP_FAKE_CIN'),
  fallbackBookingUrl: getEnvVar('REACT_APP_FALLBACK_BOOKING_URL'),
  featureFlagsEnabled: getEnvVar('REACT_APP_FEATURE_FLAGS_ENABLED') === 'true',
  featureSet: getEnvVar('REACT_APP_FEATURE_SET'),
  gatewayApiPaths: {
    cam0: getEnvVar('REACT_APP_GATEWAY_AGGREGATION_API_PATH_CAM0'),
    cam40: getEnvVar('REACT_APP_GATEWAY_AGGREGATION_API_PATH_CAM40'),
  },
  gatewayHost: getEnvVar('REACT_APP_GATEWAY_HOST'),
  gatewayTimeoutMs: getEnvVar('REACT_APP_GATEWAY_TIMEOUT_MS'),
  groupMemberCode: 'HBAP',
  isProd: getEnvVar('REACT_APP_ENV') === 'prod',
  locales: ['en', 'zh-hant'],
  samlToken: getEnvVar('REACT_APP_DEVELOPMENT_SAML_TOKEN'),
  sessionTimeoutMs: getEnvVar('REACT_APP_SESSION_TIMEOUT_MS'),
  shouldMask: true,
  tealiumTracking: {
    events: {
      customerDataAccountError: {
        restData: {
          event_content: 'invalid account-current or savings account in hsbc hong kong required',
        },
      },
    },
    firstFormPageIndex: 2,
    funnelCorridor: '-hk corridor',
    siteCountry: 'hong kong',
    siteRegion: 'asia pacific',
  },
  trackingEnvironment: getEnvVar('REACT_APP_TRACKING_ENVIRONMENT'),
  trustProxy: getEnvVar('TRUST_PROXY') === 'true',
});
