const getEscapedCharacters = characters =>
  characters
    ? characters
        .split('')
        .map(c => `\\${c}`)
        .join('')
    : '';

const getWhiteSpaceToken = allowWhiteSpace => (allowWhiteSpace ? '\\s' : '');

export const regexAlphanumericWithCharacters = (specialChars, allowWhiteSpace) => {
  const specialCharacters = getEscapedCharacters(specialChars);
  const whiteSpaceToken = getWhiteSpaceToken(allowWhiteSpace);
  return new RegExp(`^[a-zA-Z0-9${specialCharacters}${whiteSpaceToken}]*$`);
};

export const regexAlphanumericWithCharactersTinNum = (specialChars, allowWhiteSpace) => {
  const specialCharacters = getEscapedCharacters(specialChars);
  const whiteSpaceToken = getWhiteSpaceToken(allowWhiteSpace);
  return new RegExp(`^(([a-zA-Z]{1}\\d{6}([a,A]{1}|\\d{1}))|([a-zA-Z]{2}\\d{6}([a,A]{1}|\\d{1})))*$`);
};

export const regexAlphabeticWithCharacters = (specialChars, allowWhiteSpace) => {
  const specialCharacters = getEscapedCharacters(specialChars);
  const whiteSpaceToken = getWhiteSpaceToken(allowWhiteSpace);
  return new RegExp(`^[a-zA-Z${specialCharacters}${whiteSpaceToken}]*$`);
};

export const regexNumericWithCharacters = (specialChars, allowWhiteSpace) => {
  const specialCharacters = getEscapedCharacters(specialChars);
  const whiteSpaceToken = getWhiteSpaceToken(allowWhiteSpace);
  return new RegExp(`^[0-9${specialCharacters}${whiteSpaceToken}]*$`);
};

export const regexStartWithAlphanumericWithCharacters = (specialChars = '') =>
  new RegExp(`^[a-zA-Z0-9${specialChars}]+.*$`);

export const regexAnyCharacterExcept = disallowedChars => new RegExp(`^[^${getEscapedCharacters(disallowedChars)}]*$`);

export const regexNotJustWhitespace = /[^\s]/;
