import {
  LoadingPage,
  ScrollToTopWrapper,
  useConfig,
  useInboundCountryLock,
  useIsFeatureEnabled,
  useLoggerEffect,
} from '@icoz-frontends/client';
import Translate from '@icoz-frontends/translate/macro';
import { FormLayout, Heading, HorizontalRule, Section } from '@piggybank/core';
import { Form } from '@piggybank/form';
import React from 'react';
import WelcomeContent from './WelcomeContent';
import useTracking from './useTracking';

const WelcomePage = () => {
  const { corridor, logEventCodes } = useConfig();
  const isFeatureEnabled = useIsFeatureEnabled();
  useLoggerEffect(logEventCodes.WELCOME);

  const INBOUND_COUNTRIES = corridor.availableInboundCountries.map(
    country => isFeatureEnabled(`CORRIDORS:${country.abbrev.toUpperCase()}`) && country.abbrev,
  );

  const lockedInboundCountry = useInboundCountryLock(INBOUND_COUNTRIES);

  useTracking(lockedInboundCountry);

  if (lockedInboundCountry === undefined) {
    window.location.href = 'https://internationalservices.hsbc.com/overseas-account-opening/where-to-open/';
    return <LoadingPage show />;
  }

  return (
    <ScrollToTopWrapper>
      <FormLayout>
        <Section>
          <Heading level={1}>
            <Translate>welcomePage.pageTitle</Translate>
          </Heading>
          <HorizontalRule />
        </Section>

        <Form initialValues={{ inboundCountry: lockedInboundCountry || '' }}>
          {({ values }) => <WelcomeContent values={values} />}
        </Form>
      </FormLayout>
    </ScrollToTopWrapper>
  );
};

export default WelcomePage;
