import { eventBus } from '@piggybank/core';

export default class IcoFetch {
  constructor({ logEventCodes, logger } = {}) {
    this.log = async (code, metadata) => logger(logEventCodes[code], metadata);
  }

  addMethod(name, callback) {
    this[name] = callback.bind(this);
  }

  get(url, opts = {}) {
    return this._request(url, { ...opts, method: 'GET' });
  }

  post(url, opts = {}) {
    return this._request(url, {
      ...opts,
      method: 'POST',
    });
  }

  // eslint-disable-next-line class-methods-use-this
  _request(url, { method, ...rest }) {
    const opts = {
      method,
      ...rest,
    };

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      try {
        const res = await fetch(url, opts);

        if (!res.ok) {
          const error = {
            message: res.statusText,
            statusCode: res.status,
          };

          const cloneRes = res.clone();
          const text = await cloneRes.text();

          try {
            error.data = JSON.parse(text);
          } catch (e) {
            error.data = text;
          }

          res.error = error;
          resolve(res);
          return;
        }

        eventBus.dispatch({
          method,
          type: 'onApiCalled',
          url,
        });

        const cloneRes = res.clone();
        const responseJson = await cloneRes.json();
        res.data = responseJson.data || responseJson;
        resolve(res);
      } catch (e) {
        const res = {
          error: {
            message: e,
            statusCode: 500,
          },
        };

        resolve(res);
      }
    });
  }
}
