import { Announce, VisuallyHidden } from '@piggybank/core';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from './useTranslate';

const Announcer = ({ children, secondaryLocale }) => {
  const { announcement, bilingual } = useTranslate();

  return (
    <>
      {bilingual && (
        <>
          {children && <VisuallyHidden>{children}</VisuallyHidden>}
          {announcement && (
            <Announce>
              <span lang={secondaryLocale}>{announcement}</span>
            </Announce>
          )}
        </>
      )}
    </>
  );
};

Announcer.defaultProps = {
  children: undefined,
};

Announcer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  secondaryLocale: PropTypes.string.isRequired,
};

export default Announcer;
