import { SessionTimedOutPage, useConfig, useSessionTimedOutTracking } from '@icoz-frontends/client';
import Translate from '@icoz-frontends/translate/macro';
import PropTypes from 'prop-types';
import React from 'react';

const textMap = {
  cta: <Translate inline>sessionTimedOut.cta</Translate>,
  heading: <Translate>sessionTimedOut.pageTitle</Translate>,
  lead: <Translate>sessionTimedOut.leadParagraph</Translate>,
};

const SessionTimedOut = ({ location }) => {
  const { routes } = useConfig();
  useSessionTimedOutTracking(location?.state?.corridorConfig);
  return <SessionTimedOutPage restartRoute={routes.root} textMap={textMap} />;
};

SessionTimedOut.defaultProps = {
  location: {},
};

SessionTimedOut.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      corridorConfig: PropTypes.objectOf(PropTypes.any),
    }),
  }),
};

export default SessionTimedOut;
