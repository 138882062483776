import useTealiumTracking, { trackView } from './useTealiumTracking';

export default () => {
  useTealiumTracking('Processing', [
    trackView({
      application_event: 'submitted',
      funnel_step: 9,
    }),
  ]);
};
