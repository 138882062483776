import { ErrorPage, useConfig, useErrorTracking } from '@icoz-frontends/client';
import Translate from '@icoz-frontends/translate/macro';
import { Heading, Link, Paragraph } from '@piggybank/core';
import PropTypes from 'prop-types';
import React from 'react';

const textMap = {
  button: <Translate inline>errorGeneric.cta</Translate>,
  heading: <Translate>errorGeneric.main.heading</Translate>,
  helpHeading: <Translate>errorGeneric.help.heading</Translate>,
  helpParagraph: <Translate>errorGeneric.help.paragraph</Translate>,
  lead: <Translate>errorGeneric.main.paragraph</Translate>,
  title: 'Application error',
};

const GenericError = props => {
  const { corridorConfig } = props;
  const { dashboardUrl } = useConfig();
  useErrorTracking(corridorConfig);

  return (
    <ErrorPage
      customerCareContent={
        <>
          <Heading level={3} marginBottom={3}>
            <Translate>errorGeneric.customerCare.heading</Translate>
          </Heading>
          <Paragraph>
            <Link href="tel:+85222333888">+852 2233 3888</Link>
          </Paragraph>
        </>
      }
      dashboardUrl={dashboardUrl}
      textMap={textMap}
      {...props}
    />
  );
};

GenericError.defaultProps = {
  corridorConfig: {},
  errors: undefined,
};

GenericError.propTypes = {
  corridorConfig: PropTypes.objectOf(PropTypes.any),
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      detail: PropTypes.string,
      jsonPath: PropTypes.string,
      source: PropTypes.string,
    }),
  ),
};

export default GenericError;
