import { Button, Callout, Heading, Panel, Paragraph, Section } from '@piggybank/core';
import PropTypes from 'prop-types';
import React from 'react';
import ApplicationCompleteTick from '../../components/ApplicationCompleteTick';
import PageTitle from '../../components/PageTitle/PageTitle';
import useApplicationCompleteTracking from '../../hooks/tracking/useApplicationCompleteTracking';
import useConfig from '../../hooks/useConfig';
import useLoggerEffect from '../../hooks/useLoggerEffect';
import useScrollToTop from '../../hooks/useScrollToTop';
import useSessionKicker from '../../hooks/useSessionKicker';

const ApplicationComplete = ({
  applicationReference,
  applicationStatus,
  bankingCenterContactDetails,
  buttonOnKeyDown,
  contentMap,
  textMap,
}) => {
  const { dashboardUrl, logEventCodes } = useConfig();
  const { deactivate } = useSessionKicker();
  useScrollToTop();
  useLoggerEffect(logEventCodes.WIZARD_COMPLETE);
  useApplicationCompleteTracking({ applicationReference, applicationStatus });

  if (deactivate) {
    deactivate();
  }

  return (
    <>
      <PageTitle>{textMap.pageTitle}</PageTitle>

      <Section>
        <ApplicationCompleteTick />
        <Heading level={1}>{contentMap.pageTitle}</Heading>
        <Panel>
          <Heading level={3} marginBottom={2}>
            {contentMap.heading}
          </Heading>
          <Paragraph marginBottom={0}>{applicationReference}</Paragraph>
        </Panel>
        {Object.keys(contentMap.callout).length !== 0 && contentMap.callout.constructor === Object && (
          <Callout type={contentMap.callout.type}>
            <Paragraph marginBottom={0}>{contentMap.callout.body}</Paragraph>
          </Callout>
        )}
      </Section>

      {contentMap.whatsNextHeading && (
        <Section>
          <Heading accentBar level={2}>
            {contentMap.whatsNextHeading}
          </Heading>
          <Paragraph>{contentMap.whatsNextBody}</Paragraph>
        </Section>
      )}

      {contentMap.contactUsHeading && bankingCenterContactDetails && (
        <Section>
          <Heading accentBar level={2}>
            {contentMap.contactUsHeading}
          </Heading>
          <Paragraph>{contentMap.contactUsBody}</Paragraph>
          {bankingCenterContactDetails}
        </Section>
      )}

      <Section>
        <Button elementType="a" href={dashboardUrl} onKeyDown={buttonOnKeyDown && buttonOnKeyDown}>
          {contentMap.cta}
        </Button>
      </Section>
    </>
  );
};

ApplicationComplete.defaultProps = {
  applicationStatus: undefined,
  bankingCenterContactDetails: undefined,
  buttonOnKeyDown: undefined,
};

ApplicationComplete.propTypes = {
  applicationReference: PropTypes.string.isRequired,
  applicationStatus: PropTypes.string,
  bankingCenterContactDetails: PropTypes.element,
  buttonOnKeyDown: PropTypes.func,
  contentMap: PropTypes.shape({
    callout: {
      body: PropTypes.element,
      type: PropTypes.string,
    },
    contactUsBody: PropTypes.element,
    contactUsHeading: PropTypes.element,
    cta: PropTypes.element.isRequired,
    heading: PropTypes.element.isRequired,
    pageTitle: PropTypes.element.isRequired,
    whatsNextBody: PropTypes.element,
    whatsNextHeading: PropTypes.element,
  }).isRequired,
  textMap: PropTypes.shape({
    pageTitle: PropTypes.string.isRequired,
  }).isRequired,
};

export default ApplicationComplete;
