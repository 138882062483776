import { TransformJsonStringToJsx } from '@icoz-frontends/translate';
import {
  Heading,
  Link,
  ListItem,
  Reveal,
  UnorderedList,
  VisuallyHidden,
  detectBrowser,
  getMarginBottomClass,
  marginBottomLevels,
} from '@piggybank/core';
import cn from 'classnames';
import { connect } from 'formik';
import countBy from 'lodash/fp/countBy';
import isEqual from 'lodash/fp/isEqual';
import kebabCase from 'lodash/fp/kebabCase';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { scroller } from 'react-scroll';
import getNestedValues from '../utils/getNestedValues';
import styles from './form-feedback.module.css';

/* eslint-disable */

// On iOS with VoiceOver, focussing the root div doesn't work.
// Test the user agent for iOS and then focus the heading instead.

const countByValue = countBy(({ value }) => value);

class FormFeedback extends Component {
  static propTypes = {
    id: PropTypes.string,
    textMap: PropTypes.shape({
      title: PropTypes.node,
      of: PropTypes.node,
    }),
    /** docgen-from-context:<Form/> */
    formik: PropTypes.shape({
      isValid: PropTypes.bool,
      isValidating: PropTypes.bool,
      isSubmitting: PropTypes.bool,
      submitCount: PropTypes.number,
      errors: PropTypes.object,
    }).isRequired,
    /**
     * 0, 1, 2, 3, 4, 5, 6, 7
     */
    marginBottom: PropTypes.oneOf(marginBottomLevels),
  };

  static defaultProps = {
    id: 'form-feedback',
    marginBottom: 5,
    formik: {},
    textMap: {
      title: 'Please correct the following:',
      of: ' of ',
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      submitCount: 0,
      errorMessages: [],
    };

    this.ref = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.formik.isSubmitting && !props.formik.isValidating && props.formik.submitCount > state.submitCount) {
      if (props.formik.isValid) {
        return { submitCount: props.formik.submitCount };
      } else {
        return {
          submitCount: props.formik.submitCount,
          errorMessages: getNestedValues(props.formik.errors),
        };
      }
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.props.formik.isSubmitting &&
      !this.props.formik.isValidating &&
      !this.props.formik.isValid &&
      this.state.submitCount > prevState.submitCount
    ) {
      this.scrollTimeout = setTimeout(() => {
        scroller.scrollTo(this.props.id, {
          duration: 500,
          offset: -72,
          smooth: 'easeInOutCubic',
        });
        this.scrollTimeout = setTimeout(() => {
          this.ref.current.focus();
        }, 1);
      }, 1);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.scrollTimeout);
  }

  handleClick = (event, { path }) => {
    event.preventDefault();
    const fieldId = kebabCase(`field-${path}`);
    const fieldEl = document.getElementById(fieldId);

    if (!fieldEl) return;

    scroller.scrollTo(fieldId, {
      duration: 500,
      offset: -72,
      smooth: 'easeInOutCubic',
    });

    this.scrollTimeout = setTimeout(() => {
      const inputEl = fieldEl.querySelector('input,select,textarea,[data-formfocusable]');
      if (inputEl) inputEl.focus();
    }, 1);
  };

  render() {
    const {
      id,
      formik, // Supplied by connect() higher-order component from 'formik' package
      textMap,
      marginBottom,
      ...rest
    } = this.props;
    const { submitCount, errorMessages } = this.state;

    const errorMessageCounts = countByValue(errorMessages);
    const errorMessageLoopCounts = {};

    const currentErrorMessages = getNestedValues(formik.errors);
    const show = submitCount > 0 && !!errorMessages.length;

    return (
      <div
        className={styles.FormFeedback}
        id={id}
        ref={!detectBrowser.iOS ? this.ref : null}
        tabIndex="-1"
        aria-hidden={show ? null : true}
        {...rest}
      >
        <Reveal>
          {show && (
            <div className={cn(styles.container, getMarginBottomClass(marginBottom))}>
              <Heading level={2} ref={detectBrowser.iOS ? this.ref : null} marginBottom={2}>
                {textMap.title}
              </Heading>
              <UnorderedList marginBottom={0}>
                {errorMessages.map(error => {
                  const complete = !currentErrorMessages.some(isEqual(error));

                  let messageIndex;

                  if (errorMessageCounts[error.value] > 1) {
                    if (!errorMessageLoopCounts[error.value]) {
                      errorMessageLoopCounts[error.value] = 0;
                    }

                    messageIndex = ++errorMessageLoopCounts[error.value];
                  }

                  const message = (
                    <>
                      <TransformJsonStringToJsx jsonString={error.value} />
                      {messageIndex && ` ${messageIndex} ${textMap.of} ${errorMessageCounts[error.value]} `}
                    </>
                  );

                  return (
                    <ListItem key={error.path}>
                      {complete ? (
                        <>
                          <VisuallyHidden>Resolved: </VisuallyHidden>
                          <span className={styles.complete}>{message}</span>
                        </>
                      ) : (
                        <Link
                          href={`#${kebabCase(`field-${error.path}`)}`}
                          onClick={e => {
                            this.handleClick(e, error);
                          }}
                        >
                          {message}
                        </Link>
                      )}
                    </ListItem>
                  );
                })}
              </UnorderedList>
            </div>
          )}
        </Reveal>
      </div>
    );
  }
}

export { FormFeedback };

const connected = connect(FormFeedback);

connected.displayName = 'FormFeedback';

export default connected;
