import { useConfig, useLoggerEffect } from '@icoz-frontends/client';
import { MaintenancePage as Maintenance } from '@icoz-frontends/maintenance';
import Translate from '@icoz-frontends/translate/macro';
import { Heading, IconStatusInformationOnlight, Link, Paragraph, Section } from '@piggybank/core';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';

const MaintenancePage = props => {
  const { endTimestamp, startTimestamp } = props;
  const { logEventCodes } = useConfig();

  useLoggerEffect(logEventCodes.MAINTENANCE_PAGE, {
    end: dayjs(endTimestamp).toISOString(),
    start: dayjs(startTimestamp).toISOString(),
  });

  return (
    <Maintenance
      content={
        <>
          <Section>
            <Heading accentBar level={2}>
              <Translate>maintenancePage.adviceSection.heading</Translate>
            </Heading>
            <Paragraph marginBottom={6}>
              <Translate>maintenancePage.adviceSection.paragraph1</Translate>
            </Paragraph>

            <Paragraph>
              <b>
                <Translate>callCenter.international.heading</Translate>
              </b>
            </Paragraph>

            <Paragraph>
              <Link buttonPadding={false} elementType="a" href="tel:+85222333888">
                +852 2233 3888
              </Link>
            </Paragraph>
          </Section>
        </>
      }
      ctaHref="https://www.hsbc.com.hk/online/dashboard"
      iconSlot={<IconStatusInformationOnlight marginBottom={3} width="2.5em" />}
      textMap={{
        cta: <Translate inline>maintenancePage.cta</Translate>,
        leadParagraph: <Translate>maintenancePage.leadParagraph</Translate>,
        title: <Translate>maintenancePage.pageTitle</Translate>,
      }}
    />
  );
};

MaintenancePage.propTypes = {
  endTimestamp: PropTypes.number,
  startTimestamp: PropTypes.number,
};

MaintenancePage.defaultProps = {
  endTimestamp: undefined,
  startTimestamp: undefined,
};

export default MaintenancePage;
