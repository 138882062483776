import * as PropTypes from 'prop-types';
import React from 'react';
import ErrorRouter from './ErrorRouter';
import PreExistingCustomerDataContext from './PreExistingCustomerDataContext';

const PreExistingCustomerData = ({ LoadingPage, children, data, error, inboundCountry }) => {
  if (error) {
    return <ErrorRouter errors={error.data && error.data.errors} inboundCountry={inboundCountry} />;
  }

  if (!data) {
    return <LoadingPage show />;
  }

  const { applicationState, customerData } = data;

  return (
    <PreExistingCustomerDataContext.Provider value={{ applicationState, customerData }}>
      {children}
    </PreExistingCustomerDataContext.Provider>
  );
};

PreExistingCustomerData.defaultProps = {
  data: undefined,
  error: undefined,
};

PreExistingCustomerData.propTypes = {
  LoadingPage: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  error: PropTypes.shape({
    data: PropTypes.shape({
      errors: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string.isRequired,
          detail: PropTypes.string,
          jsonPath: PropTypes.string,
          source: PropTypes.string,
        }),
      ),
    }),
    statusCode: PropTypes.number,
  }),
  inboundCountry: PropTypes.string.isRequired,
};

export default PreExistingCustomerData;
