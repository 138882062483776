import { Heading, PlaybackKey, PlaybackList, PlaybackValue } from '@piggybank/core';
import PropTypes from 'prop-types';
import React from 'react';
import { formatDate } from '../../../dataFormatters';

const Review = ({ contentMap, customerData, values }) => {
  const startDate = values.currentResidentialAddressStartDate || customerData.currentResidentialAddress.startDate;

  return (
    <>
      <Heading component="h2" level={3}>
        {contentMap.heading}
      </Heading>

      <PlaybackList type="review">
        <PlaybackKey>{contentMap.heading}</PlaybackKey>
        <PlaybackValue>{customerData.currentResidentialAddress.value || contentMap.fallback}</PlaybackValue>

        <PlaybackKey>{contentMap.whenDidYouMoveIn}</PlaybackKey>
        <PlaybackValue>{formatDate(startDate, 'MM/YYYY') || contentMap.fallback}</PlaybackValue>
      </PlaybackList>
    </>
  );
};

Review.propTypes = {
  contentMap: PropTypes.shape({
    fallback: PropTypes.element.isRequired,
    heading: PropTypes.element.isRequired,
    whenDidYouMoveIn: PropTypes.element.isRequired,
  }).isRequired,
  customerData: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Review;
