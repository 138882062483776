import { Button, ButtonRow, IconSettings, ModalDialog, Reveal } from '@piggybank/core';
import { Form, Hint } from '@piggybank/form';
import { defaultTo } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import DevMode from './DevMode';
import buildConfigUpdates from './buildConfigUpdates';
import styles from './index.module.css';
import initialValues from './initialValues';
import performSideEffects from './performSideEffects';
import { removeDevSettings, retrieveDevSettings, storeDevSettings } from './storage';

const DevSettings = ({ devSettingsEnabled, updateConfig }) => {
  const enabled = useMemo(() => !!devSettingsEnabled, []); // eslint-disable-line react-hooks/exhaustive-deps
  const [values, setValues] = useState(defaultTo(retrieveDevSettings({ type: 'settings' }), initialValues));
  const [haveValuesChanged, setHaveValuesChanged] = useState(false);

  return enabled ? (
    <ModalDialog
      drawer
      getApplicationNode={() => document.getElementById('root')}
      name="devSettings"
      onClose={() => {
        setHaveValuesChanged(false);
      }}
      renderOpenButton={({ openDialog }) => (
        <span className={styles.buttonWrapper}>
          <Button iconSlotLeft={<IconSettings inline />} onClick={openDialog} secondary type="button">
            Dev settings
          </Button>
        </span>
      )}
      title="Developer settings"
    >
      <Form
        initialValues={values}
        onSubmit={formBag => {
          performSideEffects(values, formBag.values);
          const config = buildConfigUpdates(values, formBag.values);
          storeDevSettings(formBag.values, config);
          updateConfig(config);
          setValues(formBag.values);
          setHaveValuesChanged(false);
        }}
      >
        <DevMode setHaveValuesChanged={setHaveValuesChanged} />
        <ButtonRow align="left">
          <Button type="submit">Apply</Button>
          <Button
            onClick={() => {
              performSideEffects(values, initialValues);
              removeDevSettings();
              updateConfig(null);
              setValues(initialValues);
              setHaveValuesChanged(false);
            }}
            secondary
            type="button"
          >
            Reset
          </Button>
        </ButtonRow>
        <Reveal marginTop={3}>{haveValuesChanged && <Hint>Please click apply to save your changes.</Hint>}</Reveal>
      </Form>
    </ModalDialog>
  ) : null;
};

DevSettings.propTypes = {
  devSettingsEnabled: PropTypes.bool.isRequired,
  updateConfig: PropTypes.func.isRequired,
};

export default DevSettings;
