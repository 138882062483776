import deepmerge from 'deepmerge';
import type { FeatureFlagOptions, FeatureFlagsConfig } from './types';

export default (...configs: FeatureFlagsConfig): FeatureFlagsConfig =>
  deepmerge.all([...configs], {
    arrayMerge: (target: FeatureFlagsConfig, source: FeatureFlagsConfig) => {
      const destination = [...target];

      source.forEach(sourceItem => {
        const index = target.findIndex(targetItem => targetItem.label === sourceItem.label);

        if (index !== -1) {
          destination[index] = deepmerge(destination[index] as FeatureFlagOptions, sourceItem);
        } else {
          destination.push(sourceItem);
        }
      });

      return destination;
    },
  }) as FeatureFlagsConfig;
