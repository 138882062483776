import { Button, FormLayout, Heading, IconStatusErrorOnlight, Page, Paragraph, Section } from '@piggybank/core';
import React, { ReactElement } from 'react';

interface MaintenancePageProps {
  content?: React.ReactElement;
  ctaHref: string;
  iconSlot?: React.ReactElement;
  textMap: {
    cta: string;
    leadParagraph: string;
    title: string;
  };
}

export default (props: MaintenancePageProps): ReactElement => {
  const { ctaHref, iconSlot = <IconStatusErrorOnlight marginBottom={3} width="2.5em" />, textMap, content } = props;

  return (
    <Page>
      <FormLayout>
        <Section>
          {iconSlot}
          <Heading level={1}>{textMap.title}</Heading>
          <Paragraph lead>{textMap.leadParagraph}</Paragraph>
        </Section>

        {content}

        <Button elementType="a" href={ctaHref}>
          {textMap.cta}
        </Button>
      </FormLayout>
    </Page>
  );
};
