import { convertMillisecondsToMinutes } from '@icoz-frontends/shared';
import { Button, HorizontalRule, ModalDialog, Paragraph, eventBus } from '@piggybank/core';
import Cookie from 'js-cookie';
import { throttle } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import useConfig from '../../hooks/useConfig';
import useLogger from '../../hooks/useLogger';
import SessionKickerContext from './context';
import useSessionKickerTracking from './useSessionKickerTracking';

const TrackingWrapper = ({ onLoad }) => {
  useSessionKickerTracking();

  useEffect(() => {
    if (onLoad) {
      onLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

TrackingWrapper.propTypes = {
  onLoad: PropTypes.func,
};

/* eslint-disable react/require-default-props, no-fallthrough, radix */
/* Please fix linting errors next time file is touched */

const SessionKicker = ({ children, isEnabled, onGetServiceStatus, textMap }) => {
  const [lastUserInteraction, setLastUserInteraction] = useState(Date.now());
  const [now, setNow] = useState(Date.now());
  const [hasTimedOut, setHasTimedOut] = useState(false);
  const logger = useLogger();
  const config = useConfig();
  const { logEventCodes, routes, sessionTimeoutMs } = config;
  const authenticated = Cookie.get('authenticated') === 'true';
  const gatewayTimeout = Number(Cookie.get('gatewaytimeout'));
  const gatewayTimeRemaining = gatewayTimeout - Date.now();
  const sessionTimeRemaining = parseInt(sessionTimeoutMs) - (now - lastUserInteraction);

  const metadata = {
    authenticated,
    gateway_timeout: gatewayTimeout,
    last_user_interaction: lastUserInteraction,
    session_gateway_remaining: convertMillisecondsToMinutes(gatewayTimeRemaining),
    session_remaining: convertMillisecondsToMinutes(sessionTimeRemaining),
    session_timeout: sessionTimeoutMs,
  };

  const interval = useRef();

  const throttledApiCall = useRef(
    throttle(5000, async () => {
      try {
        await onGetServiceStatus();
      } catch (e) {
        if (e.statusCode === 403) {
          setHasTimedOut(true);
        }
      }
    }),
  );

  const deactivate = () => {
    if (interval.current) {
      clearInterval(interval.current);
      logger(logEventCodes.SESSION_KICKER, { ...metadata, message: 'Session Kicker deactivated.' });
    }
  };

  useEffect(() => {
    interval.current = setInterval(() => {
      setNow(Date.now());
    }, 1000);

    logger(logEventCodes.SESSION_KICKER, { ...metadata, message: 'Session Kicker activated.' });

    return deactivate;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unsubscribe = eventBus.subscribe(({ type }) => {
      if (type !== 'onApiCalled') {
        setLastUserInteraction(Date.now());
      }

      setHasTimedOut(false);
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isEnabled) {
    return children;
  }

  if (gatewayTimeRemaining > 0 && gatewayTimeRemaining < 60000 && sessionTimeRemaining > 0) {
    throttledApiCall.current();
  }

  if (!hasTimedOut && (sessionTimeRemaining < 0 || gatewayTimeRemaining < 0)) {
    const reason = gatewayTimeRemaining < 0 ? 'the gateway has timeed out' : 'the session has timeed out';

    logger(logEventCodes.SESSION_KICKER, {
      ...metadata,
      message: `The session is invalid, ${reason}. Setting session to timeout.`,
    });

    setHasTimedOut(true);
  }

  if (!authenticated) {
    /**
     * The user has been logged out in error (due to a GW bug)
     * and will not be able to submit their application.
     */
    return (
      <Redirect
        to={{
          pathname: routes.error,
          state: { corridorConfig: config },
        }}
      />
    );
  }

  if (hasTimedOut) {
    logger(logEventCodes.SESSION_KICKER, {
      ...metadata,
      message: 'The session has timed out. Setting the authenticated cookie to false.',
    });

    Cookie.set('authenticated', 'false');

    return (
      <Redirect
        to={{
          pathname: routes.sessionTimeout,
          state: { corridorConfig: config },
        }}
      />
    );
  }

  return (
    <SessionKickerContext.Provider
      value={{
        deactivate,
      }}
    >
      <ModalDialog alert dismissible={false} show={sessionTimeRemaining < 60000} title={textMap.modalTitle}>
        <TrackingWrapper
          onLoad={() => {
            logger(logEventCodes.SESSION_KICKER, { ...metadata, message: 'Time out warning pop up displayed.' });
          }}
        />
        <Paragraph marginBottom={6}>{textMap.modalQuestion}</Paragraph>
        <HorizontalRule hiddenOnSmallViewport />
        <Button type="button">{textMap.modalButton}</Button>
      </ModalDialog>
      {children}
    </SessionKickerContext.Provider>
  );
};

SessionKicker.propTypes = {
  children: PropTypes.node,
  isEnabled: PropTypes.bool,
  onGetServiceStatus: PropTypes.func,
  textMap: PropTypes.shape({
    modalButton: PropTypes.node,
    modalQuestion: PropTypes.node,
    modalTitle: PropTypes.node,
  }),
};

SessionKicker.defaultProps = {
  textMap: {
    modalButton: 'Yes, continue',
    modalQuestion: 'Do you need more time to continue your application?',
    modalTitle: 'Need more time?',
  },
};

export default SessionKicker;
