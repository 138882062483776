import DevSettings from '@icoz-frontends/dev-settings';
import { Page } from '@piggybank/core';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import useConfig from '../../hooks/useConfig';
import useScrollToTop from '../../hooks/useScrollToTop';
import IcoToolbeltContext from '../IcoToolbelt/IcoToolbeltContext';

const ScrollToTopWrapper = ({ children, ...props }) => {
  const { devSettingsEnabled } = useConfig();
  const { updateConfig } = useContext(IcoToolbeltContext);

  /**
   * Ensure `Page` is scrolled to the top on mount.
   * https://reacttraining.com/react-router/web/guides/scroll-restoration
   */
  useScrollToTop();

  return (
    <Page {...props}>
      <DevSettings devSettingsEnabled={devSettingsEnabled} updateConfig={updateConfig} />
      {children}
    </Page>
  );
};

ScrollToTopWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ScrollToTopWrapper;
