import { conf } from '@icoz-frontends/shared';
import { TranslateProvider } from '@icoz-frontends/translate';
import { Wizard, WizardPage } from '@piggybank/form';
import PropTypes from 'prop-types';
import React from 'react';
import createLogger from '../../createLogger';
import { createIcoFetch } from '../../icoFetch';
import IcoToolbelt from '../IcoToolbelt/IcoToolbelt';

const StorybookPageWrapper = ({ children, config, initialValues, withoutWizard }) => {
  const mergedConfig = conf.mergeConfigs(conf.globalConfig, config);

  return (
    <IcoToolbelt
      config={mergedConfig}
      icoFetch={createIcoFetch(mergedConfig)}
      logger={createLogger(mergedConfig.routes.splunk, false)}
    >
      <TranslateProvider>
        <div className="pb-Page__Page" style={{ marginTop: 'var(--unit5)' }}>
          <div className="pb-Grid__Grid">
            <div className="pb-Grid__Row">
              <div className="pb-Grid__Cell pb-Grid__size-12 pb-Grid__sizeMedium-9 pb-Grid__sizeLarge-6 pb-Grid__offsetLarge-1">
                {withoutWizard ? (
                  children
                ) : (
                  <Wizard initialValues={initialValues} key="mainFlow" onSubmit={() => {}}>
                    <WizardPage>{children}</WizardPage>
                  </Wizard>
                )}
              </div>
            </div>
          </div>
        </div>
      </TranslateProvider>
    </IcoToolbelt>
  );
};

StorybookPageWrapper.defaultProps = {
  config: undefined,
  initialValues: {},
  withoutWizard: false,
};

StorybookPageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.objectOf(PropTypes.any),
  initialValues: PropTypes.objectOf(PropTypes.any),
  withoutWizard: PropTypes.bool,
};

export default StorybookPageWrapper;
