import { TranslateProvider } from '@icoz-frontends/translate';
import { Wizard, WizardPage } from '@piggybank/form';
import { render } from '@testing-library/react';
import { createBrowserHistory } from 'history';
import React from 'react';
import { isElement } from 'react-is';
import IcoToolbelt from '../components/IcoToolbelt/IcoToolbelt';
import createIcoToolbeltArgs from './createIcoToolbeltArgs';

const pageSetup = (value, { config, customerData, initialValues = {}, withoutWizard = false } = {}) => {
  const valueIsElement = isElement(value);
  let Component, element;

  if (valueIsElement) {
    element = value;
  } else {
    Component = value;
  }

  const renderWithoutWizard = () =>
    valueIsElement ? element : <Component customerData={customerData} values={initialValues} />;

  const renderWithWizard = () => (
    <Wizard history={createBrowserHistory()} initialValues={{ ...initialValues }} key="mainFlow" onSubmit={() => {}}>
      {valueIsElement ? (
        <WizardPage>{element}</WizardPage>
      ) : (
        <WizardPage>
          <Component customerData={customerData} values={initialValues} />
        </WizardPage>
      )}
    </Wizard>
  );

  const renderResults = render(
    <IcoToolbelt {...createIcoToolbeltArgs({ config })}>
      <TranslateProvider>{withoutWizard ? renderWithoutWizard() : renderWithWizard()}</TranslateProvider>
    </IcoToolbelt>,
  );

  const getValueForKey = (text, options = {}) =>
    renderResults.getByText(text, options).closest('dt').nextSibling.textContent;

  const getAllValuesForKey = text =>
    renderResults.getAllByText(text).map(node => node.closest('dt').nextSibling.textContent);

  const getAllTranslatedValuesForKey = text =>
    renderResults.queryAllByText(text).map(node => node.parentElement.parentElement.parentElement.innerHTML);

  const getTranslatedLabel = text => renderResults.queryByText(text).parentElement.parentElement.parentElement;

  return {
    ...renderResults,
    getAllTranslatedValuesForKey,
    getAllValuesForKey,
    getTranslatedLabel,
    getValueForKey,
  };
};

export default pageSetup;
