import React, { useEffect, useRef, useState } from 'react';
import usePrevious from '../../hooks';

export interface ScheduledMaintenanceProps {
  enabled?: boolean;
  endTimestamp: number;
  homeSlot: React.ReactElement;
  maintenanceSlot: React.ReactElement;
  startTimestamp: number;
}

export default (props: ScheduledMaintenanceProps): React.ReactElement | null => {
  const { enabled = false, endTimestamp, homeSlot, maintenanceSlot, startTimestamp } = props;
  const [showMaintenance, setShowMaintenance] = useState(false);
  const previousShowMaintenance = usePrevious<boolean>(showMaintenance);
  const interval = useRef<number>();

  const shouldShow = () => {
    const currentTimestamp = Date.now();

    return currentTimestamp >= startTimestamp && currentTimestamp < endTimestamp;
  };

  useEffect(() => {
    if (enabled) {
      setShowMaintenance(shouldShow());

      interval.current = window.setInterval(() => setShowMaintenance(shouldShow()), 1000);
    }

    return () => {
      window.clearInterval(interval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (enabled && !showMaintenance && previousShowMaintenance) return homeSlot;

  return enabled && showMaintenance ? maintenanceSlot : null;
};
