import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

/* eslint-disable react/require-default-props, react/forbid-prop-types, react/no-array-index-key */
/* Please fix linting errors next time file is touched */

const propTypes = {
  appDynamicsKeys: PropTypes.exact({
    prodAppKey: PropTypes.string.isRequired,
    testAppKey: PropTypes.string.isRequired,
  }),
  browserWindow: PropTypes.object,
  enabled: PropTypes.bool,
  environment: PropTypes.oneOf(['dev', 'qa', 'prod']),
  tealiumConfig: PropTypes.exact({
    accountId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
  }),
};

const Tracking = ({ enabled = false, tealiumConfig, appDynamicsKeys, environment = 'dev', browserWindow = window }) => {
  const appDynamicsTag = '//cdn.appdynamics.com/adrum/adrum-latest.js';
  const scripts = [[appDynamicsTag, 'defer', true]];
  const UDO = { site_type: 'single_page' };

  if (tealiumConfig) {
    scripts.push([
      `//tags.tiqcdn.com/utag/${tealiumConfig.accountId}/${tealiumConfig.profileId}/${environment}/utag.sync.js`,
      'async',
      true,
    ]);

    scripts.push([`window.utag_data=${JSON.stringify(UDO)}`, 'async', false]);

    scripts.push([
      `//tags.tiqcdn.com/utag/${tealiumConfig.accountId}/${tealiumConfig.profileId}/${environment}/utag.js`,
      'async',
      true,
    ]);
  }

  // appDynamics config
  if (appDynamicsKeys) {
    browserWindow['adrum-start-time'] = new Date().getTime();
    const appDynamicsConfig = browserWindow['adrum-config'] || {};

    appDynamicsConfig.appKey = environment === 'dev' ? appDynamicsKeys.testAppKey : appDynamicsKeys.prodAppKey;

    appDynamicsConfig.adrumExtUrlHttp = 'http://cdn.appdynamics.com';
    appDynamicsConfig.adrumExtUrlHttps = 'https://cdn.appdynamics.com';
    appDynamicsConfig.beaconUrlHttp = 'http://col.eum-appdynamics.com';
    appDynamicsConfig.beaconUrlHttps = 'https://col.eum-appdynamics.com';
    appDynamicsConfig.xd = { enable: false };

    browserWindow['adrum-config'] = appDynamicsConfig;
  }

  return (
    <Helmet>
      {enabled &&
        scripts.map(([value, executionType, isSrc], index) => {
          const props = { [executionType]: true, key: index };

          if (isSrc) {
            props.src = value;
            return <script {...props} />;
          }

          return <script {...props}>{value}</script>;
        })}
    </Helmet>
  );
};

Tracking.propTypes = propTypes;

export default Tracking;
