import { useInitDevSettings } from '@icoz-frontends/dev-settings';
import { defaultTo, isFunction } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import IcoToolbeltContext from './IcoToolbeltContext';
import { mergeConfigs, mergeFormatters, mergeIcoFetch } from './utils';

const IcoToolbelt = ({
  children,
  config,
  createIcoFetch,
  createIsFeatureEnabled,
  createLogger,
  formatters,
  overrideRoot,
}) => {
  const ctx = defaultTo(useContext(IcoToolbeltContext), {});
  const root = !Object.keys(ctx).length;
  const mergedConfig = mergeConfigs(ctx.config, config);

  const [devSettingsConfig, setDevSettingsConfig] = useState(
    useInitDevSettings({ isProd: mergedConfig.isProd, root: root || overrideRoot }),
  );

  const toolbeltConfig = mergeConfigs(mergedConfig, devSettingsConfig);
  const { featureFlagsEnabled, featureSet, isProd, routes } = toolbeltConfig;
  const logger = isFunction(createLogger) ? createLogger(routes.splunk, isProd) : ctx.logger;
  const icoFetch = isFunction(createIcoFetch) ? createIcoFetch(toolbeltConfig, logger) : undefined;

  const value = {
    config: toolbeltConfig,
    formatters: ctx.formatters ? mergeFormatters(ctx.formatters, formatters) : formatters,
    icoFetch: ctx.icoFetch ? mergeIcoFetch(ctx.icoFetch, icoFetch) : icoFetch,
    isFeatureEnabled: isFunction(createIsFeatureEnabled)
      ? createIsFeatureEnabled(featureFlagsEnabled, featureSet)
      : ctx.isFeatureEnabled,
    logger,
    updateConfig:
      !ctx.updateConfig || overrideRoot
        ? values => {
            setDevSettingsConfig(values);
          }
        : ctx.updateConfig,
  };

  return (
    <IcoToolbeltContext.Provider value={value}>
      {isFunction(children) ? children(value) : children}
    </IcoToolbeltContext.Provider>
  );
};

IcoToolbelt.defaultProps = {
  config: undefined,
  createIcoFetch: undefined,
  createIsFeatureEnabled: undefined,
  createLogger: undefined,
  formatters: undefined,
  overrideRoot: false,
};

IcoToolbelt.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  config: PropTypes.objectOf(PropTypes.any),
  createIcoFetch: PropTypes.func,
  createIsFeatureEnabled: PropTypes.func,
  createLogger: PropTypes.func,
  formatters: PropTypes.objectOf(PropTypes.object),
  overrideRoot: PropTypes.bool,
};

export default IcoToolbelt;
