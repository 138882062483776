export default {
  BROWSER_EXCEPTION: {
    code: 'BROWSER_EXCEPTION',
    error_category: 'INTERNAL_SERVER_ERROR',
    error_code: 'BROWSER_EXCEPTION',
    error_message: 'A browser exception has prevented the user from proceeding with their application',
    log_format: 'ERROR_ADVICE',
  },
  DOC_API_SUCCESS: {
    application_event: 'DOC_API_SUCCESS',
    application_event_details: 'Document upload was successful',
    code: 'DOC_API_SUCCESS',
    log_format: 'APPLICATION_EVENT',
  },
  DOC_API_UPLOAD: {
    application_event: `DOC_API_UPLOAD`,
    application_event_details: `The document upload request to DocUpload`,
    code: 'DOC_API_UPLOAD',
    log_format: 'APPLICATION_EVENT',
  },
  DOC_API_UPLOAD_ERROR: {
    code: 'DOC_API_UPLOAD_ERROR',
    error_category: 'EXTERNAL_SYSTEM_ERROR',
    error_code: 'DOC_API_UPLOAD_ERROR',
    error_message: `The document upload request to DocUpload failed`,
    log_format: 'ERROR_ADVICE',
  },
  ELIGIBILITY_ERROR: {
    application_event: 'ELIGIBILITY_ERROR',
    application_event_details: 'A user has landed on the eligibility error page.',
    code: 'ELIGIBILITY_ERROR',
    log_format: 'APPLICATION_EVENT',
  },
  ERROR_PAGE: {
    application_event: 'ERROR_PAGE',
    application_event_details: 'A user has landed on the error page.',
    code: 'ERROR_PAGE',
    log_format: 'APPLICATION_EVENT',
  },
  FORM_INTERACTION: {
    application_event: 'FORM_INTERACTION',
    application_event_details: 'A user has interacted with the following fields: %{fields}',
    code: 'FORM_INTERACTION',
    log_format: 'APPLICATION_EVENT',
  },
  HTTP_REQUEST: {
    code: 'HTTP_REQUEST',
    log_format: 'HTTP_REQUEST',
  },
  HTTP_RESPONSE: {
    code: 'HTTP_RESPONSE',
    log_format: 'HTTP_RESPONSE',
  },
  INVALID_DATA_ERROR: {
    application_event: 'INVALID_DATA_ERROR',
    application_event_details: 'A user has landed on the invalid data error page.',
    code: 'INVALID_DATA_ERROR',
    log_format: 'APPLICATION_EVENT',
  },
  MAINTENANCE_PAGE: {
    application_event: 'MAINTENANCE_PAGE',
    application_event_details: 'A user has landed on the maintenance page.',
    code: 'MAINTENANCE_PAGE',
    log_format: 'APPLICATION_EVENT',
  },
  NODE_EXCEPTION: {
    code: 'NODE_EXCEPTION',
    error_category: 'INTERNAL_SERVER_ERROR',
    error_code: 'NODE_EXCEPTION',
    error_message: 'A node exception has prevented the user from proceeding with their application',
    log_format: 'ERROR_ADVICE',
  },
  NODE_SESSION: {
    application_event: 'NODE_SESSION',
    application_event_details: 'Node session lifecycle',
    code: 'NODE_SESSION',
  },
  PAGE_NOT_FOUND: {
    application_event: 'PAGE_NOT_FOUND',
    application_event_details: 'The page the user is looking for does not exist.',
    code: 'PAGE_NOT_FOUND',
    log_format: 'APPLICATION_EVENT',
  },
  SEGMENT_ERROR: {
    application_event: 'SEGMENT_ERROR',
    application_event_details: 'A user has landed on the segment error page.',
    code: 'SEGMENT_ERROR',
    log_format: 'APPLICATION_EVENT',
  },
  SESSION_KICKER: {
    application_event: 'SESSION_KICKER',
    application_event_details: 'Session kicker lifecycle',
    code: 'SESSION_KICKER',
  },
  SESSION_TIMEOUT: {
    application_event: 'SESSION_TIMEOUT',
    application_event_details: 'An application session has timed out',
    code: 'SESSION_TIMEOUT',
    log_format: 'APPLICATION_EVENT',
  },
  WELCOME: {
    application_event: 'WELCOME',
    application_event_details: 'A user has reached the Welcome page',
    code: 'WELCOME',
    log_format: 'APPLICATION_EVENT',
  },
  WELCOME_CORRIDOR_SELECTED: {
    application_event: 'WELCOME_CORRIDOR_SELECTED',
    application_event_details: `A user has selected a corridor application`,
    code: 'WELCOME_CORRIDOR_SELECTED',
    log_format: 'APPLICATION_EVENT',
  },
  WIZARD_COMPLETE: {
    application_event: 'WIZARD_COMPLETE',
    application_event_details: 'A user has completed all the steps of the wizard',
    code: 'WIZARD_COMPLETE',
    log_format: 'APPLICATION_EVENT',
  },
};
