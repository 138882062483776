import { eventBus } from '@piggybank/core';
import { useContext, useEffect, useRef, useState } from 'react';
import PreExistingCustomerDataContext from '../../../components/PreExistingCustomerData/PreExistingCustomerDataContext';
import useConfig from '../../useConfig';
import makeEventPayload from './payloads/event';
import makeValidationMessageEventPayload from './payloads/validationMessageEvent';
import makeValueEventPayload from './payloads/valueEvent';
import makeViewPayload from './payloads/view';
import { hasValue, matches } from './utils';

const onNavEvent = { component: 'Wizard', type: 'onNavigate' };
const onErrorEvent = { component: 'Form', type: 'onError' };

const hasUTAGScript = () =>
  !!Array.from(document.getElementsByTagName('script')).filter(script => script.src.indexOf('/utag.js') !== -1).length;

const useTealiumTracking = (pageTitle, trackings = [], { asUnauthenticatedPage = false, sendTracking = true } = {}) => {
  const prefillData = useContext(PreExistingCustomerDataContext);
  const config = useConfig();
  const [isTMSReady, setIsTMSReady] = useState(false);
  const checkTMSInterval = useRef();

  useEffect(() => {
    if (!isTMSReady && window.TMS) {
      setIsTMSReady(true);
    } else if (!isTMSReady && hasUTAGScript()) {
      checkTMSInterval.current = setInterval(() => {
        if (window.TMS) {
          setIsTMSReady(true);

          clearInterval(checkTMSInterval.current);
        }
      }, 500);
    }

    return () => {
      clearInterval(checkTMSInterval.current);
    };
  }, [sendTracking, isTMSReady]);

  useEffect(() => {
    const { tealiumTracking } = config;
    const { customerSegment, customerSegments, productType, productTypes } = tealiumTracking;

    const segmentConfig =
      customerSegment && customerSegments && customerSegments[customerSegment.toLowerCase()]
        ? customerSegments[customerSegment.toLowerCase()]
        : {};

    const productConfig = productType && productTypes && productTypes[productType] ? productTypes[productType] : {};

    const sharedConfig = {
      ...tealiumTracking,
      ...segmentConfig,
      ...productConfig,
    };

    const view = trackings.find(x => x.type === 'view');

    if (view && sendTracking && isTMSReady) {
      const viewPayload = makeViewPayload(pageTitle, view.asErrorPage, view.rest, asUnauthenticatedPage, sharedConfig);

      try {
        // The tealium sandbox has a bug that sometimes causes an additional view event to be
        // raised with an empty payload, which causes an error to the app.
        // This only happens for trackView invocations.
        // Catch those spurious calls and swallow the error as it is a non-production issue.
        window.TMS.trackView(viewPayload);
      } catch {
        // no catch
      }
    }

    const events = trackings.filter(t => t.type === 'event');
    const validationMessages = trackings.filter(t => t.type === 'error');
    const fields = trackings.filter(t => t.type === 'field');

    const unsubscribe = eventBus.subscribe(action => {
      events
        .filter(e => matches(e.selector, action))
        .forEach(e => {
          const event = makeEventPayload(pageTitle, e.label, e.content, asUnauthenticatedPage, sharedConfig, e.rest);

          if (sendTracking && isTMSReady) {
            window.TMS.trackEvent(event);
          }
        });

      if (matches(onNavEvent, action)) {
        fields
          .filter(
            f =>
              action.currentPageIndex === f.pageNumber + sharedConfig.firstFormPageIndex &&
              hasValue(f, { ...prefillData, ...action.values }),
          )
          .forEach(f => {
            const fieldEvent = makeValueEventPayload(
              pageTitle,
              f.label,
              f.inputLabel,
              f.selector({ ...prefillData, ...action.values }),
              asUnauthenticatedPage,
              sharedConfig,
              f.rest,
            );

            if (sendTracking && isTMSReady) {
              window.TMS.trackEvent(fieldEvent);
            }
          });
      }

      if (matches(onErrorEvent, action)) {
        validationMessages
          .filter(e => !!action.errors[e.field])
          .forEach(e => {
            const { field, transformErrorValue } = e;
            const errorValue = action.errors[field];
            const content = typeof transformErrorValue === 'function' ? transformErrorValue(errorValue) : errorValue;

            const errorEvent = makeValidationMessageEventPayload(
              pageTitle,
              e.label,
              content,
              asUnauthenticatedPage,
              sharedConfig,
              e.rest,
            );

            if (sendTracking && isTMSReady) {
              window.TMS.trackEvent(errorEvent);
            }
          });
      }
    });

    // we want to wait for the containing Wizard's onNavigate event to be captured,
    // so the event subscription needs to outlive the unmount of the current component
    // for one event cycle, since the current component is likely a child component of WizardPage.
    return () => setTimeout(unsubscribe, 0);

    // eslint-disable-next-line
  }, [sendTracking, isTMSReady]);
};

export default useTealiumTracking;
