export default (_initialValue, value) => {
  if (value === 'PROD') {
    return {
      env: 'prod',
      featureFlagsEnabled: false,
      featureSet: 'PROD',
      isProd: true,
      trackingEnvironment: 'prod',
    };
  }

  return {};
};
