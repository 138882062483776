import buildDevModeConfigUpdates from './DevMode/buildConfigUpdates';

const updaters = {
  devMode: buildDevModeConfigUpdates,
};

export default (initialValues, values) =>
  Object.keys(values).reduce((obj, key) => {
    const updater = updaters[key];

    if (!updater) {
      return obj;
    }

    return { ...obj, ...updater(initialValues[key], values[key]) };
  }, {});
