import PropTypes from 'prop-types';
import React from 'react';
import styles from './SemanticList.module.css';

/* eslint-disable react/require-default-props */
/* Please fix linting errors next time file is touched */

const propTypes = {
  children: PropTypes.node,
  ordered: PropTypes.bool,
};

const SemanticList = ({ children, ordered }) => {
  const TagType = ordered ? 'ol' : 'ul';
  return <TagType className={styles.list}>{children}</TagType>;
};

SemanticList.propTypes = propTypes;

export default SemanticList;
