import cookies from 'js-cookie';
import isString from 'lodash/fp/isString';

export const readCookie = name => {
  const value = cookies.get(name);

  return isString(value) && value.length > 0 ? JSON.parse(value) : {};
};

export const removeCookie = name => {
  cookies.remove(name);
};

export const writeCookie = (name, value) => {
  cookies.set(name, JSON.stringify(value));
};
