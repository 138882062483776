import type { FeatureState } from './types';

const backendFeatureFlags = ['USE_AUTH', 'USE_STUBS'];

export default (featureState: FeatureState): string => {
  try {
    const sharedFeatureState = Object.keys(featureState).reduce((shared, featureName) => {
      if (backendFeatureFlags.includes(featureName)) {
        shared[featureName] = featureState[featureName] as boolean;
      }

      return shared;
    }, {} as FeatureState);

    return JSON.stringify(sharedFeatureState);
  } catch {
    return '{}';
  }
};
