import { Callout, Heading, Paragraph, Section, getMarginBottomClass } from '@piggybank/core';
import { Hint, Label } from '@piggybank/form';
import PropTypes from 'prop-types';
import React from 'react';
import { formatDate } from '../../../dataFormatters';
import FieldWithAnnounce from '../../FieldWithAnnounce';
import { LockedDataKey, LockedDataList, LockedDataValue } from '../../LockedDataList/LockedDataList';
import FieldFeedback from '../../piggybank/FieldFeedback/FieldFeedback';

const Component = ({
  contentMap,
  customerData,
  dateInput,
  formBag,
  residentialStatus,
  shouldAskForPreviousAddresses,
  textMap,
}) => {
  const { setFieldValue, values } = formBag;

  return (
    <Section marginBottom={5}>
      <Heading accentBar level={2}>
        {contentMap.heading}
      </Heading>

      {contentMap.paragraph && <Paragraph marginBottom={5}>{contentMap.paragraph}</Paragraph>}

      <LockedDataList marginBottom={5}>
        <LockedDataKey>{contentMap.label}</LockedDataKey>
        <LockedDataValue marginBottom={5}>
          <div className={getMarginBottomClass(5)}>
            {customerData.currentResidentialAddress?.value || contentMap.fallback}
          </div>
          {contentMap.notification && (
            <Callout type="key-information">
              <Paragraph marginBottom={0} small>
                {contentMap.notification}
              </Paragraph>
            </Callout>
          )}
        </LockedDataValue>

        {!dateInput && (
          <>
            <LockedDataKey>{contentMap.dateYouMovedIn}</LockedDataKey>
            <LockedDataValue>
              {formatDate(customerData.currentResidentialAddress?.startDate) || contentMap.fallback}
            </LockedDataValue>
          </>
        )}
      </LockedDataList>

      {residentialStatus && (
        <FieldWithAnnounce announce={textMap.announceResidentialStatus} marginBottom={5} name="residentialStatus">
          <Label>{contentMap.residentialStatus}</Label>
          {residentialStatus}
          <FieldFeedback />
        </FieldWithAnnounce>
      )}

      {dateInput && (
        <FieldWithAnnounce
          announce={textMap.announceWhenDidYouMoveIn}
          marginBottom={shouldAskForPreviousAddresses(values.currentResidentialAddressStartDate) ? 5 : 0}
          name="currentResidentialAddressStartDate"
          onChange={(next, rest) => {
            if (
              !shouldAskForPreviousAddresses ||
              !shouldAskForPreviousAddresses(values.currentResidentialAddressStartDate)
            ) {
              setFieldValue('previousResidentialAddresses', []);
            }

            next(rest);
          }}
          required
        >
          <Label>{contentMap.whenDidYouMoveIn}</Label>
          <Hint>{contentMap.whenDidYouMoveInHint}</Hint>
          {dateInput}
          <FieldFeedback />
        </FieldWithAnnounce>
      )}
    </Section>
  );
};

Component.defaultProps = {
  dateInput: undefined,
  residentialStatus: undefined,
  shouldAskForPreviousAddresses: undefined,
  textMap: {},
};

Component.propTypes = {
  contentMap: PropTypes.shape({
    dateYouMovedIn: PropTypes.element,
    fallback: PropTypes.element.isRequired,
    heading: PropTypes.element.isRequired,
    label: PropTypes.element.isRequired,
    notification: PropTypes.element,
    paragraph: PropTypes.element,
    residentialStatus: PropTypes.element,
    whenDidYouMoveIn: PropTypes.element,
    whenDidYouMoveInHint: PropTypes.element,
  }).isRequired,
  customerData: PropTypes.objectOf(PropTypes.any).isRequired,
  dateInput: PropTypes.element,
  formBag: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setValues: PropTypes.func,
    values: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
  residentialStatus: PropTypes.element,
  shouldAskForPreviousAddresses: PropTypes.func,
  textMap: PropTypes.shape({
    announceResidentialStatus: PropTypes.string,
    announceWhenDidYouMoveIn: PropTypes.string,
  }),
};

export default Component;
