import { Button, FormLayout, Heading, IconStatusErrorOnlight, Paragraph, Section } from '@piggybank/core';
import PropTypes from 'prop-types';
import React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import ScrollToTopWrapper from '../../components/ScrollToTopWrapper/ScrollToTopWrapper';
import useConfig from '../../hooks/useConfig';
import useLoggerEffect from '../../hooks/useLoggerEffect';
import styles from './PageNotFoundPage.module.css';

const PageNotFoundPage = ({ dashboardUrl, textMap }) => {
  const { logEventCodes } = useConfig();
  useLoggerEffect(logEventCodes.PAGE_NOT_FOUND);

  return (
    <ScrollToTopWrapper>
      <FormLayout>
        <PageTitle>{textMap.title}</PageTitle>

        <Section>
          <div className={styles.icon}>
            <IconStatusErrorOnlight scaled />
          </div>

          <Heading level={1}>{textMap.heading}</Heading>
          <Paragraph lead marginBottom={6}>
            {textMap.lead}
          </Paragraph>

          <Button elementType="a" href={dashboardUrl}>
            {textMap.button}
          </Button>
        </Section>
      </FormLayout>
    </ScrollToTopWrapper>
  );
};

PageNotFoundPage.propTypes = {
  dashboardUrl: PropTypes.string,
  textMap: PropTypes.shape({
    button: PropTypes.node,
    heading: PropTypes.node,
    lead: PropTypes.node,
    title: PropTypes.string,
  }),
};

PageNotFoundPage.defaultProps = {
  dashboardUrl: '#',
  textMap: {
    button: <>Go to My accounts</>,
    heading: <>Something went wrong</>,
    lead: <>We can&#39;t find the page you&#39;re looking for. It might be an old link or have moved elsewhere.</>,
    title: '404',
  },
};

export default PageNotFoundPage;
