import { Heading, Paragraph, Reveal, Section } from '@piggybank/core';
import PropTypes from 'prop-types';
import React from 'react';
import { formatDate } from '../../../dataFormatters';
import { LockedDataKey, LockedDataList, LockedDataValue } from '../../LockedDataList/LockedDataList';
import SemanticList from '../../SemanticList/SemanticList';
import AddAnother from './AddAnother';
import { deriveWhereDidYouLiveBeforeDate } from './utils';

const Component = ({
  addressFields,
  contentMap,
  customerData,
  dateFormat,
  formBag,
  initialValues,
  locked,
  max,
  pinnedCountries,
  shouldAskForPreviousAddresses,
  showAdd,
}) => {
  const { setFieldValue, setValues, values } = formBag;

  if (locked && !!customerData.previousResidentialAddresses.length) {
    return (
      <Section marginBottom={5}>
        <Heading level={3}>{contentMap.heading}</Heading>
        <SemanticList ordered>
          {customerData.previousResidentialAddresses.slice(0, max).map((address, index) => (
            <li key={address.startDate}>
              <LockedDataList>
                <LockedDataKey>{contentMap.subHeading(index + 1)}</LockedDataKey>
                <LockedDataValue>{address.value}</LockedDataValue>
                <LockedDataKey>{contentMap.dateYouMovedIn}</LockedDataKey>
                <LockedDataValue>{formatDate(address.startDate)}</LockedDataValue>
              </LockedDataList>
            </li>
          ))}
        </SemanticList>
      </Section>
    );
  }

  if (!locked) {
    return (
      <Reveal duration={150}>
        {shouldAskForPreviousAddresses && shouldAskForPreviousAddresses(values.currentResidentialAddressStartDate) && (
          <Section marginBottom={5}>
            <Heading level={3}>{contentMap.heading}</Heading>
            {contentMap.paragraph1 && <Paragraph>{contentMap.paragraph1}</Paragraph>}

            <AddAnother
              contentMap={{ addAddress: contentMap.addAddress, removeAddress: contentMap.removeAddress }}
              itemInitialValue={initialValues}
              max={max}
              showAdd={showAdd}
            >
              {({ index, path }) => (
                <>
                  {max > 1 && <Heading level={4}>{contentMap.subHeading(index + 1)}</Heading>}
                  <Paragraph marginBottom={5}>
                    {contentMap.whereDidYouLiveBefore(
                      formatDate(deriveWhereDidYouLiveBeforeDate(values, index), dateFormat),
                    )}
                  </Paragraph>

                  {addressFields({
                    country: values.previousResidentialAddresses[index]?.country,
                    index,
                    initialValues,
                    marginBottom: 5,
                    max,
                    onChange: (next, rest) => {
                      const { previousResidentialAddresses } = values;

                      previousResidentialAddresses[index] = {
                        ...initialValues,
                      };

                      setFieldValue('previousResidentialAddresses', previousResidentialAddresses);
                      next(rest);
                    },
                    path,
                    pinnedCountries,
                    setFieldValue,
                    setValues,
                    values,
                    withStartDate: true,
                  })}
                </>
              )}
            </AddAnother>
          </Section>
        )}
      </Reveal>
    );
  }

  return null;
};

Component.defaultProps = {
  customerData: {},
  dateFormat: 'MM/YYYY',
  initialValues: {},
  locked: false,
  max: 3,
  pinnedCountries: [],
  shouldAskForPreviousAddresses: undefined,
  showAdd: true,
};

Component.propTypes = {
  addressFields: PropTypes.func.isRequired,
  contentMap: PropTypes.shape({
    addAddress: PropTypes.element,
    dateYouMovedIn: PropTypes.element,
    heading: PropTypes.element.isRequired,
    paragraph1: PropTypes.element,
    removeAddress: PropTypes.element,
    subHeading: PropTypes.func.isRequired,
    whereDidYouLiveBefore: PropTypes.func,
  }).isRequired,
  customerData: PropTypes.objectOf(PropTypes.any),
  dateFormat: PropTypes.string,
  formBag: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setValues: PropTypes.func,
    values: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any),
  locked: PropTypes.bool,
  max: PropTypes.number,
  pinnedCountries: PropTypes.arrayOf(PropTypes.string),
  shouldAskForPreviousAddresses: PropTypes.func,
  showAdd: PropTypes.bool,
};

export default Component;
