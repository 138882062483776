import { VisuallyHidden } from '@piggybank/core';
import { connect } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import ExtendedAddAnother from '../../../ExtendedAddAnother/ExtendedAddAnother';
import './index.css';

const ConnectedExtendedAddAnother = connect(ExtendedAddAnother);

const AddAnother = ({ children, contentMap, itemInitialValue, max, showAdd }) => (
  <div className={max === 1 ? 'add-another--single-address' : undefined}>
    <ConnectedExtendedAddAnother
      fullWidth
      itemInitialValue={itemInitialValue}
      marginBottom={0}
      max={max}
      min={1}
      name="previousResidentialAddresses"
      renderAddButtonText={({ length }) => (
        <>
          {contentMap.addAddress}
          <VisuallyHidden>{length + 1}</VisuallyHidden>
        </>
      )}
      renderRemoveButtonText={({ index }) => (
        <>
          {contentMap.removeAddress}
          <VisuallyHidden>{index + 1}</VisuallyHidden>
        </>
      )}
      showAdd={showAdd}
    >
      {children}
    </ConnectedExtendedAddAnother>
  </div>
);

AddAnother.defaultProps = {
  contentMap: {},
  showAdd: true,
};

AddAnother.propTypes = {
  children: PropTypes.func.isRequired,
  contentMap: PropTypes.shape({
    addAddress: PropTypes.element,
    removeAddress: PropTypes.element,
  }),
  itemInitialValue: PropTypes.objectOf(PropTypes.any).isRequired,
  max: PropTypes.number.isRequired,
  showAdd: PropTypes.bool,
};

export default AddAnother;
