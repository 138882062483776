export const BILINGUAL = 'BILINGUAL' as const;
export const CONFIRM_LEAVE = 'CONFIRM_LEAVE' as const;
export const DISPLAY_ERROR_MESSAGE = 'DISPLAY_ERROR_MESSAGE' as const;
export const USE_DSP_AUTH = 'USE_DSP_AUTH' as const;
export const LOG_FORM_INTERACTIONS = 'LOG_FORM_INTERACTIONS' as const;
export const MOCK_DYNAMODB = 'MOCK_DYNAMODB' as const;
export const USE_AUTH = 'USE_AUTH' as const;
export const SAVE_AND_RETRIEVE = 'SAVE_AND_RETRIEVE' as const;
export const SCHEDULED_MAINTENANCE = 'SCHEDULED_MAINTENANCE' as const;
export const SESSION_TIMEOUT = 'SESSION_TIMEOUT' as const;
export const USE_FORM_VALIDATION = 'USE_FORM_VALIDATION' as const;
export const USE_STUBS = 'USE_STUBS' as const;
export const USE_TRACKING = 'USE_TRACKING' as const;
