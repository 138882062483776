import has from 'lodash/fp/has';

export function omitDefaults(defaults, overrides) {
  return Object.entries(overrides).reduce(
    (acc, [name, val]) => (val === defaults[name] ? acc : { ...acc, [name]: val }),
    {},
  );
}

export function assignDefaults(defaults, overrides) {
  return Object.keys(defaults).reduce(
    (acc, name) => ({ ...acc, [name]: has(name, overrides) ? overrides[name] : defaults[name] }),
    {},
  );
}

export const groupOptions = options =>
  Object.keys(options).reduce(
    (acc, label) => {
      if (label.indexOf(':') !== -1) {
        const groupName = label.split(':')[0].toLowerCase();

        if (!acc[groupName]) {
          acc[groupName] = {};
        }

        acc[groupName][label] = options[label];
      } else {
        acc.features[label] = options[label];
      }

      return acc;
    },
    { features: {} },
  );
