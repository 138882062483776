import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

/* eslint-disable react/require-default-props */
/* Please fix linting errors next time file is touched */

const propTypes = {
  children: PropTypes.node.isRequired,
  enabled: PropTypes.bool,
};

/**
 * This component can be used to force a complete remount of its children
 * by invoking window.triggerRemount(). This is mostly useful for browser testing,
 * when a full page load is not the most effective approach, e.g. by requiring
 * redundant data GETs.
 *
 * Production builds may want to pass in `enabled={false}`, in which case
 * Remountable has no effect.
 */
const CypressRemount = ({ children, enabled = true }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (!enabled) {
      return undefined;
    }

    if (window.triggerRemount) {
      throw new Error('multiple Remountable components are not currently supported');
    }

    window.triggerRemount = () => setShow(!show);
    return () => delete window.triggerRemount;
  }, [show, enabled]);

  if (enabled && !show) {
    setTimeout(() => setShow(true), 0);
    return null;
  }

  return <>{children}</>;
};

CypressRemount.propTypes = propTypes;

export default CypressRemount;
